import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Tooltip, Popover, Modal, Table, ConfigProvider, theme } from "antd";
import Layout from "./tools/layout";
import BrightnessIcon from "./tools/BrightnessIcon";
import ZoomIcon from "./tools/ZoomIcon";
import PanIcon from "./tools/PanIcon";
import StackScrollIcon from "./tools/StackScrollIcon.jsx";
import ResetIcon from "./tools/ResetIcon";
import SyncIcon from "./tools/SyncIcon";
import DicomList from "./DicomList.jsx";
import DicomEngine from "./DicomEngine.jsx";
import RotateIconBar from "./tools/RotateIconBar.jsx";
import MainRotateIcon from "./tools/MainRotateIcon.jsx";
import Rotate90IconBar from "./tools/Rotate90IconBar.jsx";
import RotateInverse90IconBar from "./tools/RotateInverse90IconBar.jsx";
import ProbeIcon from "./tools/ProbeIcon.jsx";
import MainLineIcon from "./tools/MainLineIcon.jsx";
import LineIconBar from "./tools/LineIconBar.jsx";
import BidirectionalIconBar from "./tools/BidirectionalIconBar.jsx";
import MainAngleIcon from "./tools/MainAngleIcon.jsx";
import AngleIconBar from "./tools/AngleIconBar.jsx";
import CobbAngleIconBar from "./tools/CobbAngleIconBar.jsx";
import MainRectangleRoiIcon from "./tools/MainRectangleRoiIcon.jsx";
import RectangleRoiIconBar from "./tools/RectangleRoiIconBar.jsx";
import CircleRoiIconBar from "./tools/CircleRoiIconBar.jsx";
import EllipticalRoiIconBar from "./tools/EllipticalRoiIconBar.jsx";
import DeleteIcon from "./tools/DeleteIcon.jsx";
import ColorPaletteIcon from "./tools/ColorPaletteIcon.jsx";
import ColorIcon from "./tools/ColorIcon.jsx";
import WWWCsettingIcon from "./tools/WWWCsettingIcon.jsx";
import WWWCsettingNameIcon from "./tools/WWWCsettingNameIcon.jsx";
import TagIcon from "./tools/TagIcon.jsx";
import Result from "./Result.jsx";
import { ViewerResult } from "../../api/patientApi.jsx";
import { openDB, getEntry } from "../indexedDB/idbUtils.js";
import { GridAction } from "../../store/action";
import { LoadingOutlined } from "@ant-design/icons";

const { darkAlgorithm } = theme;

const ViewerContent = styled.div`
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
`;
const SideBarTool = styled.div`
  padding: 5px;
  margin: 0;
  width: 84px;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: #212121;
  align-items: center;
`;
const CenterContent = styled.div`
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #000;
`;
const HeaderTool = styled.div`
  padding: 0 10px;
  margin: 0;
  width: 100%;
  height: 75px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
`;

const InfoBar = styled.div`
  padding: 0;
  margin: 0;
  min-width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #000;
`;
const ToolIcon = styled.div`
  padding: 10px;
  margin: 0;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
  cursor: pointer;
  &:hover {
    background-color: #323232;
  }
  border-radius: 5px;
`;

const ResultBar = styled.div`
  margin: 10px 0;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const DicomListBar = styled.div`
  overflow-y: auto;
  padding: 12px;
  margin: 0;
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #212121;
`;
const DicomListTitleContainer = styled.div`
  padding: 5px;
  margin: 0;
  display: flex;
  align-items: center;
`;
const DicomListTitle = styled.div`
  color: #4bf2cc;
  font-family: "Pretendard";
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;
const DicomListContainer = styled.div`
  margin: 0;
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const PopOverContents = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  width:100%
  height: 100%;
`;

const LoadingLayout = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #0e0e0e;
  opacity: 0.9;
  align-items: center;
  justify-content: center;
  color: #fff;
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
`;

const DicomViewer = ({ user_seq, patient_seq }) => {
  const dispatch = useDispatch();
  const userName = useSelector((state) => state.UserDetailsReducer.userName);
  //드래그 여부
  const [isProcessing, setIsProcessing] = useState(false);
  const [activeDragItem, setActiveDragItem] = useState(null);

  const handleDragStart = (viewportId) => {
    setActiveDragItem(viewportId);
  };

  const handleDragEnd = () => {
    setActiveDragItem(null);
  };

  //처음에 dicom engine load 상태 관리
  const [engineReady, setEngineReady] = useState(false);
  const [readyStatus, setReadyStatus] = useState({
    port2: false,
    port3: false,
    port4: false,
  });

  const handleDicomListReady = (portId) => {
    setReadyStatus((prevStatus) => ({
      ...prevStatus,
      [portId]: true,
    }));
  };

  useEffect(() => {
    // 모든 포트가 준비되었는지 확인
    if (Object.values(readyStatus).every((status) => status)) {
      setEngineReady(true);
    }
  }, [readyStatus]);

  const [isReady, setIsReady] = useState(false); // isReady 상태 관리

  // DicomEngine 준비 완료 시 호출되는 함수
  const handleEngineReady = () => {
    setIsReady(true); // 준비 완료 시 isReady를 true로 설정
  };

  const [isSync, setIsSync] = useState(false);
  const [tagOpen, setTagOpen] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [viewerResultData, setViewerResultData] = useState([]);
  const [threshold, setThreshold] = useState(null);

  const [connection, setConnection] = useState(false);

  const fetchData = async () => {
    const params = {
      user_seq: user_seq,
      patient_seq: patient_seq,
    };
    try {
      const data = await ViewerResult(params);
      const viewerResult_data = data.data[0];
      setViewerResultData(viewerResult_data);

      if (viewerResult_data.mean != null) {
        setConnection(true);
        dispatch(GridAction(2, 2)); // mean이 있을 때 2x2 그리드 설정
      } else {
        dispatch(GridAction(1, 1)); // mean이 없을 때 1x1 그리드 설정
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchThreshold = async () => {
    try {
      const db = await openDB("thresholdDB", "thresholds");
      //console.log("DB opened:", db);
      const entry = await getEntry(
        db,
        "thresholds",
        `${userName}_thresholdValue`
      );
      //console.log("Entry fetched:", entry);
      if (entry && entry.value !== undefined) {
        //console.log("Setting threshold to:", entry.value);
        setThreshold(entry.value);
      } else {
        console.log("No entry found, using default threshold.");
        setThreshold(parseFloat(3).toFixed(2)); // 기본값 설정
      }
    } catch (error) {
      console.log("Error fetching threshold:", error);
      setThreshold(parseFloat(3).toFixed(2)); // 오류 발생 시 기본값 설정
    }
  };

  const childRef = useRef();

  useEffect(() => {
    fetchData();
    fetchThreshold();
  }, [user_seq, patient_seq]);

  useEffect(() => {
    //console.log("Threshold updated:", threshold);
  }, [threshold]);

  const handleSync = () => {
    setIsSync(!isSync);
  };

  const rotateContent = (
    <PopOverContents>
      <RotateIconBar />
      <Rotate90IconBar
        handlePortRotate={() => childRef.current.handlePortRotate()}
      />
      <RotateInverse90IconBar
        handlePortRotateInverse={() =>
          childRef.current.handlePortRotateInverse()
        }
      />
    </PopOverContents>
  );
  const lineContent = (
    <PopOverContents>
      <LineIconBar />
      <BidirectionalIconBar />
    </PopOverContents>
  );
  const angleContent = (
    <PopOverContents>
      <AngleIconBar />
      <CobbAngleIconBar />
    </PopOverContents>
  );
  const rectangleContent = (
    <PopOverContents>
      <RectangleRoiIconBar />
      <CircleRoiIconBar />
      <EllipticalRoiIconBar />
    </PopOverContents>
  );
  const handleColor = (color) => {
    childRef.current.handleColor(color);
  };
  const colorPalatteContent = (
    <PopOverContents>
      <ColorIcon name="None" id="Grayscale" handleColor={handleColor} />
      <ColorIcon name="Blues" id="Blues" handleColor={handleColor} />
      <ColorIcon name="Bone" id="bone_Matlab" handleColor={handleColor} />
      <ColorIcon name="Cool warm" id="Cool to Warm" handleColor={handleColor} />
      <ColorIcon name="Copper" id="copper_Matlab" handleColor={handleColor} />
      <ColorIcon name="Hot" id="2hot" handleColor={handleColor} />
      <ColorIcon
        name="Hot Iron"
        id="RED_TEMPERATURE"
        handleColor={handleColor}
      />
      <ColorIcon
        name="Hot Metal Blue"
        id="Magma (matplotlib)"
        handleColor={handleColor}
      />
      <ColorIcon name="HSV" id="hsv" handleColor={handleColor} />
      <ColorIcon name="JET" id="jet" handleColor={handleColor} />
      <ColorIcon name="Rainbow" id="rainbow" handleColor={handleColor} />
      <ColorIcon
        name="Spectral"
        id="erdc_rainbow_dark"
        handleColor={handleColor}
      />
      <ColorIcon
        name="Summer"
        id="Linear YGB 1211g"
        handleColor={handleColor}
      />
      <ColorIcon
        name="Winter"
        id="erdc_blue2green_BW"
        handleColor={handleColor}
      />
    </PopOverContents>
  );

  const handleWWWC = (settingName) => {
    if (childRef.current) {
      childRef.current.handleWWWC(settingName);
    }
  };

  const wwwcContent = (
    <PopOverContents>
      <WWWCsettingNameIcon name="Lung" id="Lung" handleWWWC={handleWWWC} />
      <WWWCsettingNameIcon name="Brain" id="Brain" handleWWWC={handleWWWC} />
      <WWWCsettingNameIcon
        name="Abdomen"
        id="Abdomen"
        handleWWWC={handleWWWC}
      />
      <WWWCsettingNameIcon name="Bone" id="Bone" handleWWWC={handleWWWC} />
      <WWWCsettingNameIcon name="Liver" id="Liver" handleWWWC={handleWWWC} />
    </PopOverContents>
  );

  const onTagsUpdated = (value) => {
    //console.log("Tags updated:", value); // 디버그 메시지 추가
    setDataSource(value);
  };

  useEffect(() => {
    //console.log("Tags updated!!!!!!!:", dataSource);
    // dataSource가 변경될 때마다 로그 출력
  }, [dataSource]);

  if (threshold === null) {
    return <div>Loading...</div>;
  }

  return (
    <ViewerContent>
      {viewerResultData.mean !== null && !engineReady && (
        <LoadingLayout>
          <LoadingOutlined
            style={{
              color: "#fff",
              fontSize: "80px",
            }}
          />
        </LoadingLayout>
      )}
      <SideBarTool>
        <Tooltip
          placement="right"
          color={"#323232"}
          arrow={false}
          title={"Level"}
        >
          <ToolIcon>
            <BrightnessIcon />
          </ToolIcon>
        </Tooltip>
        <Tooltip
          placement="right"
          color={"#323232"}
          arrow={false}
          title={"Zoom"}
        >
          <ToolIcon>
            <ZoomIcon />
          </ToolIcon>
        </Tooltip>
        <Tooltip
          placement="right"
          color={"#323232"}
          arrow={false}
          title={"Pan"}
        >
          <ToolIcon>
            <PanIcon />
          </ToolIcon>
        </Tooltip>
        <Tooltip
          placement="right"
          color={"#323232"}
          arrow={false}
          title={"Stack Scroll"}
        >
          <ToolIcon>
            <StackScrollIcon />
          </ToolIcon>
        </Tooltip>
        <Popover placement="right" arrow={false} content={rotateContent}>
          <ToolIcon>
            <MainRotateIcon />
          </ToolIcon>
        </Popover>
        <Tooltip
          placement="right"
          color={"#323232"}
          arrow={false}
          title={"Probe"}
        >
          <ToolIcon>
            <ProbeIcon />
          </ToolIcon>
        </Tooltip>
        <Popover placement="right" arrow={false} content={lineContent}>
          <ToolIcon>
            <MainLineIcon />
          </ToolIcon>
        </Popover>
        <Popover placement="right" arrow={false} content={angleContent}>
          <ToolIcon>
            <MainAngleIcon />
          </ToolIcon>
        </Popover>
        <Popover placement="right" arrow={false} content={rectangleContent}>
          <ToolIcon>
            <MainRectangleRoiIcon />
          </ToolIcon>
        </Popover>
        <Tooltip
          placement="right"
          color={"#323232"}
          arrow={false}
          title={"Select the anotation and Delete"}
        >
          <ToolIcon>
            <DeleteIcon handleDelete={() => childRef.current.handleDelete()} />
          </ToolIcon>
        </Tooltip>
      </SideBarTool>
      <CenterContent>
        <HeaderTool>
          <Tooltip placement="bottom" color={"#323232"} arrow={false}>
            <ToolIcon>
              <Layout />
            </ToolIcon>
          </Tooltip>
          <Tooltip
            placement="bottom"
            color={"#323232"}
            arrow={false}
            title={"Sync viewports"}
          >
            <ToolIcon onClick={handleSync}>
              <SyncIcon isActive={isSync} />
            </ToolIcon>
          </Tooltip>
          <Tooltip
            placement="bottom"
            color={"#323232"}
            arrow={false}
            title={"Reset viewports"}
          >
            <ToolIcon onClick={() => childRef.current.handleReset()}>
              <ResetIcon />
            </ToolIcon>
          </Tooltip>

          <Popover
            placement="bottom"
            arrow={false}
            content={colorPalatteContent}
          >
            <ToolIcon>
              <ColorPaletteIcon />
            </ToolIcon>
          </Popover>
          <Popover placement="bottom" arrow={false} content={wwwcContent}>
            <ToolIcon>
              <WWWCsettingIcon />
            </ToolIcon>
          </Popover>
          <ToolIcon onClick={() => setTagOpen(true)}>
            <TagIcon />
          </ToolIcon>
          <ConfigProvider
            theme={{
              algorithm: darkAlgorithm,
            }}
          >
            <Modal
              title="DICOM Tags"
              open={tagOpen}
              footer={null}
              onCancel={() => setTagOpen(false)}
              style={{
                top: 150,
                maxWidth: "1000px",
                maxHeight: "500px",
              }}
              width={"100%"}
            >
              <Table
                dataSource={dataSource}
                columns={[
                  {
                    title: "Tag description",
                    dataIndex: "tag",
                    key: "tag",
                  },
                  {
                    title: "Value",
                    dataIndex: "value",
                    key: "value",
                  },
                ]}
                pagination={true}
                scroll={{ y: 550 }}
              />
            </Modal>
          </ConfigProvider>
        </HeaderTool>
        <DicomEngine
          user_seq={user_seq}
          patient_seq={patient_seq}
          renderingEngineId="LucaRenderingEngine"
          toolGroupId="LucaToolGroup"
          imageSliceSynchronizerId="LUCA_SYNCHRONIZER"
          studyInstanceUID={viewerResultData.studyInstanceUID}
          seriesInstanceUID={viewerResultData.seriesInstanceUID}
          studyUID_1={viewerResultData.studyUID_1}
          seriesUID_1={viewerResultData.seriesUID_1}
          studyUID_2={viewerResultData.studyUID_2}
          seriesUID_2={viewerResultData.seriesUID_2}
          studyUID_3={viewerResultData.studyUID_3}
          seriesUID_3={viewerResultData.seriesUID_3}
          ref={childRef}
          isSync={isSync}
          onTagsUpdated={onTagsUpdated}
          onReady={handleEngineReady}
        />
      </CenterContent>
      <InfoBar>
        <ResultBar
          style={{ height: viewerResultData.mean == null ? "100%" : "auto" }}
        >
          <Result
            result={viewerResultData.mean != null}
            risk={viewerResultData.mean}
            threshold={threshold}
            patientName={viewerResultData.patientName}
            patientSex={viewerResultData.gender === "F" ? "Female" : "Male"}
            patientID={viewerResultData.patientID}
            patientAge={viewerResultData.age}
            user_seq={user_seq}
            patient_seq={patient_seq}
            studyInstanceUID={viewerResultData.studyInstanceUID}
            seriesInstanceUID={viewerResultData.seriesInstanceUID}
            age={viewerResultData.age}
            gender={viewerResultData.gender}
            setConnection={setConnection}
            fetchData={fetchData}
            resultAI={viewerResultData.resultAI}
            section={viewerResultData.section}
          />
        </ResultBar>
        {viewerResultData.mean != null && (
          <DicomListBar>
            <DicomListTitleContainer>
              <DicomListTitle>Series</DicomListTitle>
            </DicomListTitleContainer>
            <DicomListContainer>
              <DicomList
                renderingEngineId="RenderingEngineList-1"
                viewportId="port-1"
                StudyInstanceUID={viewerResultData.studyInstanceUID}
                SeriesInstanceUID={viewerResultData.seriesInstanceUID}
                name="original"
                note="original Dicom image"
                isProcessing={isProcessing}
                setIsProcessing={setIsProcessing}
                activeDragItem={activeDragItem}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                isReady={isReady}
                onReady={() => handleDicomListReady("port1")}
              />
              <DicomList
                renderingEngineId="RenderingEngineList-2"
                viewportId="port-2"
                StudyInstanceUID={viewerResultData.studyUID_1}
                SeriesInstanceUID={viewerResultData.seriesUID_1}
                name="segment"
                note="segmented Dicom image"
                isProcessing={isProcessing}
                setIsProcessing={setIsProcessing}
                activeDragItem={activeDragItem}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                isReady={isReady}
                onReady={() => handleDicomListReady("port2")}
              />
              <DicomList
                renderingEngineId="RenderingEngineList-3"
                viewportId="port-3"
                StudyInstanceUID={viewerResultData.studyUID_2}
                SeriesInstanceUID={viewerResultData.seriesUID_2}
                name="original_gradcam"
                note="original gradcam image"
                isProcessing={isProcessing}
                setIsProcessing={setIsProcessing}
                activeDragItem={activeDragItem}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                isReady={isReady}
                onReady={() => handleDicomListReady("port3")}
              />
              <DicomList
                renderingEngineId="RenderingEngineList-4"
                viewportId="port-4"
                StudyInstanceUID={viewerResultData.studyUID_3}
                SeriesInstanceUID={viewerResultData.seriesUID_3}
                name="segment_gradcam"
                note="segmented gradcam image"
                isProcessing={isProcessing}
                setIsProcessing={setIsProcessing}
                activeDragItem={activeDragItem}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                isReady={isReady}
                onReady={() => handleDicomListReady("port4")}
              />
            </DicomListContainer>
          </DicomListBar>
        )}
      </InfoBar>
    </ViewerContent>
  );
};
export default DicomViewer;
