import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  CloseCircleFilled,
  CheckCircleFilled,
  UploadOutlined,
} from "@ant-design/icons";
import * as dicomParser from "dicom-parser"; // DICOM 파서
import { Empty, message } from "antd";
import { MultiSearch } from "../../api/insertPatientApi";
import { LoadingOutlined } from "@ant-design/icons";

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;

  label {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 0;
    background-color: #fff;
    padding: 7px 10px;
    cursor: pointer;
    gap: 10px;
    border: 0;
    margin: 0;
    font-size: 13.5px;
    font-weight: 400;
    color: #0d0d0d;
    transform: translateY(-1px);
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #e6e6e6;
    }
  }

  input[type="file"] {
    display: none;
  }
`;

const FolderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 23rem;
  gap: 1rem;
  padding: 2rem 0;
`;

const FolderItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  > div {
    display: flex;
    align-items: center;
    gap: 8px;

    > h2 {
      font-size: 14px;
      font-weight: 400;
      color: #fff;
    }

    > h3 {
      font-size: 13.5px;
      font-weight: 400;
    }
  }
`;

const EmptyFolderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 3rem 0 3rem 0;
  gap: 10px;

  > p {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
  }
`;

const CustomEmpty = styled(Empty)`
  .ant-empty-description {
    color: #fff;
  }
`;

const LoadingBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  & * {
    color: #fff;
    font-size: 3rem;
  }
`;

const STATUS_COLOR = {
  pass: "#09ff0d",
  already: "#9e9e9e",
  metaData_error: "#ff3636",
  empty: "#ff3636",
  dcm_error: "#ff3636",
  default: "#ff3636",
};

const STATUS_TEXT = {
  pass: "File upload available",
  already: "Previously uploaded file",
  metaData_error: "Unable to save",
  empty: "Empty Directory",
  dcm_error: "Unable to save",
  default: "Unable to save",
};

const STATUS_ICON = {
  pass: <CheckCircleFilled />,
  already: <CheckCircleFilled />,
  metaData_error: <CloseCircleFilled />,
  empty: <CloseCircleFilled />,
  dcm_error: <CloseCircleFilled />,
  default: <CloseCircleFilled />,
};

const RefreshUpload = ({
  onFolderPathChange,
  onFolderDataChange,
  folderStructure,
  folderStatus,
}) => {
  const userName = useSelector((state) => state.UserDetailsReducer.userName);
  const [inputKey, setInputKey] = useState(Date.now()); // input 리셋용 key
  const [loading, setLoading] = useState(false); // 파일 로딩 상태

  // 폴더 이름 추출
  const extractFolderNames = (files, depth = 1) => {
    setLoading(true); // 로딩 시작
    return Array.from(
      new Set(
        files
          .filter((file) => file.webkitRelativePath.split("/").length > depth)
          .map((file) => file.webkitRelativePath.split("/")[depth - 1])
      )
    );
  };

  // API 호출 및 이미 존재하는 폴더 이름 가져오기
  const fetchAlreadyFolders = async (userId, userPath) => {
    const params = { user_id: userId, userPath };
    try {
      const alreadyFolder = await MultiSearch(params);
      return alreadyFolder?.map((folder) => folder.folderName) || [];
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  // 폴더별 파일 분류
  const classifyFilesByFolder = (files, nestedFolderNames) => {
    const folderMap = {};
    nestedFolderNames.forEach((folderName) => {
      folderMap[folderName] = files.filter(
        (file) => file.webkitRelativePath.split("/")[1] === folderName
      );
    });
    return folderMap;
  };

  // 폴더 상태 계산
  const calculateFolderStatus = async (
    folderMap,
    alreadyFolderNames,
    folderPathName
  ) => {
    const folderStatusMap = {};
    for (const [folderName, files] of Object.entries(folderMap)) {
      if (files.length === 0) {
        folderStatusMap[folderName] = "empty";
        continue;
      }
      if (alreadyFolderNames.includes(folderName)) {
        folderStatusMap[folderName] = "already";
        continue;
      }

      let metaDataError = false;
      let dcmError = false;
      let uniqueMetaData = null;

      for (const file of files) {
        if (!file.name.endsWith(".dcm")) {
          dcmError = true;

          break;
        }

        try {
          const fileBuffer = await file.arrayBuffer();
          const dataSet = dicomParser.parseDicom(new Uint8Array(fileBuffer));

          let gender = dataSet.string("x00100040");
          let age = dataSet.string("x00101010");

          if (gender !== "M" && gender !== "F") {
            gender = "";
          }

          age = (age || "").replace(/[^\d]/g, "");
          while (age.startsWith("0")) {
            age = age.slice(1);
          }
          if (age === "") {
            age = "";
          }

          const metaData = {
            folderPathName,
            folderName,
            patientName: dataSet.string("x00100010"),
            age,
            gender,
            patientID: dataSet.string("x00100020"),
            section:
              dataSet.string("x00080060") + "-" + dataSet.string("x00180015"),
            seriesInstanceUID: dataSet.string("x0020000e"),
            studyInstanceUID: dataSet.string("x0020000d"),
            seriesDate: dataSet.string("x00080021"),
            studyDate: dataSet.string("x00080020"),
            studyDescription: dataSet.string("x00081030"),
          };

          const isValidMetaData = Object.values(metaData).every(
            (value) => value && value.trim() !== ""
          );

          if (!isValidMetaData) {
            metaDataError = true;
            break;
          }

          if (!uniqueMetaData) {
            uniqueMetaData = metaData;
          } else if (
            JSON.stringify(uniqueMetaData) !== JSON.stringify(metaData)
          ) {
            metaDataError = true;
            break;
          }
        } catch (error) {
          metaDataError = true;
          break;
        }
      }

      if (dcmError) {
        folderStatusMap[folderName] = "dcm_error";
      } else if (metaDataError) {
        folderStatusMap[folderName] = "metaData_error";
      } else {
        folderStatusMap[folderName] = "pass";
      }
    }
    return folderStatusMap;
  };

  // 파일 선택 처리
  const handleFolderSelection = async (event) => {
    const files = Array.from(event.target.files);
    if (!files || files.length === 0) {
      return handleEmptyFolder("Folder is empty");
    }

    const folderPathName = extractFolderNames(files, 1)[0];
    const nestedFolderNames = extractFolderNames(files, 2);

    if (nestedFolderNames.length === 0) {
      return handleEmptyFolder("No directory", folderPathName);
    }

    const alreadyFolderNames = await fetchAlreadyFolders(
      userName,
      folderPathName
    );
    const folderMap = classifyFilesByFolder(files, nestedFolderNames);
    const folderStatusMap = await calculateFolderStatus(
      folderMap,
      alreadyFolderNames,
      folderPathName
    );

    onFolderPathChange(folderPathName);
    onFolderDataChange(folderMap, folderStatusMap);
    setInputKey(Date.now());

    setLoading(false); // 로딩 종료
  };

  // 빈 폴더 처리 함수
  const handleEmptyFolder = (messageText, folderPathName = null) => {
    if (folderPathName) onFolderPathChange(folderPathName);
    onFolderDataChange({}, {}); // 부모 상태 초기화
    message.destroy();
    message.error(messageText);
    setInputKey(Date.now());

    setLoading(false); // 로딩 종료
  };

  // 폴더 목록 렌더링
  const renderEmptyState = () => (
    <EmptyFolderContainer>
      <CustomEmpty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </EmptyFolderContainer>
  );

  const renderFolderItems = () => {
    if (loading) {
      return (
        <LoadingBox>
          <LoadingOutlined />
        </LoadingBox>
      );
    }

    if (Object.keys(folderStructure).length === 0) return renderEmptyState();

    const STATUS_ORDER = [
      "pass",
      "already",
      "metaData_error",
      "empty",
      "dcm_error",
    ];

    // 폴더 항목을 정렬
    const sortedFolderEntries = Object.entries(folderStructure).sort(
      ([folderNameA], [folderNameB]) => {
        const statusA = folderStatus[folderNameA] || "default";
        const statusB = folderStatus[folderNameB] || "default";
        return STATUS_ORDER.indexOf(statusA) - STATUS_ORDER.indexOf(statusB);
      }
    );

    return sortedFolderEntries.map(([folderName]) => {
      const status = folderStatus[folderName] || "default";
      const color = STATUS_COLOR[status];
      const statusText = STATUS_TEXT[status];
      const statusIcon = STATUS_ICON[status];

      return (
        <FolderItem key={folderName}>
          <div>
            <h2>{folderName}</h2>
          </div>
          <div>
            <h3 style={{ color }}>{statusText}</h3>
            <span style={{ color }}>{statusIcon}</span>
          </div>
        </FolderItem>
      );
    });
  };

  return (
    <div>
      <InputContainer>
        <label htmlFor="file-upload-button">
          <UploadOutlined />
          Select Folder
        </label>
        <input
          id="file-upload-button"
          key={inputKey}
          type="file"
          webkitdirectory="true"
          onChange={handleFolderSelection}
        />
      </InputContainer>
      <FolderContainer>{renderFolderItems()}</FolderContainer>
    </div>
  );
};

export default RefreshUpload;
