import React from "react";
import styled from "styled-components";
import { Modal } from "antd";
import SampleQR from "../../assets/sampleQR.png";

const PatientModal = styled(Modal)`
  .ant-modal-content {
    //color: #fefefe;
  }

  .ant-modal-title {
    display: flex;
    justify-content: center;
    //color: #fefefe;
    font-family: "Pretendard";
    font-size: 25px;
    font-weight: 800;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  span {
    font-family: "Pretendard";
    margin: 0;
  }

  input,
  label,
  button {
    font-family: "Pretendard";
  }
  div {
    box-sizing: border-box;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 13px;
  padding: 5px;
`;

const Content = styled.div`
  display: flex;
  width: 87%;
  padding: 0 15px;

  > div {
    display: flex;
    flex-direction: column;

    > h1 {
      font-size: 17px;
      font-weight: 700;
    }

    > div {
      display: flex;
      flex-direction: column;

      > h1 {
        font-size: 17px;
        font-weight: 700;
      }

      > p {
        font-size: 15px;
        font-weight: 500;
      }
    }
  }
`;

const QRcontainer = styled.div`
  display: flex;
  flex-direction: row !important;
  gap: 27px;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;

    > h2 {
      font-size: 15px;
      font-weight: 500;
    }
  }
`;

const QRImg = styled.img`
  width: 100px;
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Btn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  font-family: "Pretendard";
  color: #fff;
  background-color: #329b83;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 25px;
  transition: 0.3s;

  &:hover {
    background-color: #2c7e6d;
  }
`;

const LucaInfoModal = ({ open, setIsRefreshModalOpen }) => {
  const handleCancel = () => {
    setIsRefreshModalOpen(false);
  };

  return (
    <PatientModal
      title="표시·기재사항"
      onCancel={handleCancel}
      open={open}
      footer={null}
      style={{ top: 55 }}
    >
      <Container>
        <Content style={{ borderTop: "1px solid #989898", paddingTop: "15px" }}>
          <div>
            <h1>1.&nbsp;</h1>
          </div>
          <div>
            <div>
              <h1>제조업자의 상호 및 주소</h1>
            </div>
            <div>
              <p>(1) 제조업자의 상호: 바이오링크 주식회사</p>
            </div>
            <div>
              <p>(2) 제조업자의 주소: 대구광역시 달서구 달구벌대로 1053, </p>
            </div>
            <div>
              <p>404호 계명대학교첨단산업지원센터(호산동) </p>
            </div>
          </div>
        </Content>
        <Content>
          <div>
            <h1>2.&nbsp; </h1>
          </div>
          <div>
            <div>
              <h1>품목허가번호 : 허가 후 기재</h1>
            </div>
          </div>
        </Content>
        <Content>
          <div>
            <h1>3.&nbsp; </h1>
          </div>
          <div>
            <div>
              <h1>명칭(제품명,품목명,모델명)</h1>
            </div>
            <div>
              <p>3등급 의료영상 검출·진단보조 소프트웨어, BL-LucaCast</p>
            </div>
          </div>
        </Content>
        <Content>
          <div>
            <h1>4.&nbsp;</h1>
          </div>
          <div>
            <div>
              <h1>소프트웨어 버전: v 1.0.1</h1>
            </div>
          </div>
        </Content>
        <Content>
          <div>
            <h1>5.&nbsp;</h1>
          </div>
          <div>
            <div>
              <h1>포장단위 : 1개</h1>
            </div>
          </div>
        </Content>
        <Content>
          <div>
            <h1>6.&nbsp;</h1>
          </div>
          <div>
            <div>
              <h1>의료기기 표준코드</h1>
            </div>
            <QRcontainer>
              <div>
                <QRImg src={SampleQR} />
                <p style={{ color: "#747474", fontWeight: 500 }}>
                  본 QR은 샘플입니다
                </p>
              </div>
              <div>
                <h2>(01) UID-DI 발급 후 기재(GS1)</h2>
                <h2>(10) v 1.0.0 (버전정보)</h2>
              </div>
            </QRcontainer>
          </div>
        </Content>
        <Content>
          <div>
            <h1>7.&nbsp;</h1>
          </div>
          <div>
            <div>
              <h1>사용목적</h1>
            </div>
            <div>
              <p>
                본 제품은 흉부 CT 영상 및 임상 정보를 기반으로 인공지능 모델을
                활용하여 향후 5년 이내에 폐암이 발생할 가능성을 수치화하여
                제공함으로써 의료진의 진단을 보조하는 목적으로 사용된다.
              </p>
            </div>
          </div>
        </Content>
        <Content>
          <div>
            <h1>8.&nbsp;</h1>
          </div>
          <div>
            <div>
              <h1>사용방법 및 사용 시 주의사항</h1>
            </div>
            <div>
              <p>사용 설명서 참고</p>
            </div>
          </div>
        </Content>
        <Content
          style={{ borderBottom: "1px solid #989898", paddingBottom: "15px" }}
        >
          <div>
            <h1>9.&nbsp;</h1>
          </div>
          <div>
            <div>
              <h1>본 제품은 "의료기기"임</h1>
            </div>
          </div>
        </Content>
        <BtnContainer>
          <Btn onClick={handleCancel}>닫기</Btn>
        </BtnContainer>
      </Container>
    </PatientModal>
  );
};

export default LucaInfoModal;
