import {
  volumeLoader,
  setVolumesForViewports,
  imageLoader,
} from "@cornerstonejs/core";

const volumeName = "LUCAVOLUME";
const volumeLoaderScheme = "cornerstoneStreamingImageVolume";

const lungLowerHU = -1450;
const lungUpperHU = -50;

//3d 볼륨 렌더링
export const loadVolume = async (
  renderingEngine,
  viewportId,
  imageIds,
  name,
  user_seq,
  patient_seq
) => {
  const vp = renderingEngine.getViewport(viewportId);

  // 캐시된 볼륨을 무시하고 새로 로드하기 위해 unique한 volumeId 생성
  const volumeId = `${volumeLoaderScheme}:${volumeName}:${name}:${user_seq}:${patient_seq}`;

  // 새롭게 볼륨을 로드하고 캐시에 저장
  const volume = await volumeLoader.createAndCacheVolume(volumeId, {
    imageIds: imageIds,
  });

  // 볼륨 로드 완료 후 설정 적용
  await volume.load();

  setVolumesForViewports(
    renderingEngine,
    [{ volumeId: volumeId }],
    [viewportId]
  );

  // 초기화 후 VOI 설정 및 렌더링
  setTimeout(() => {
    vp.setProperties({
      voiRange: { lower: -1450, upper: 50 },
    });
    vp.render();
  }, 0);
};

//3d 볼륨 렌더링 업데이트
export const updateVolume = async (
  renderingEngine,
  viewportId,
  imageIds,
  name
) => {
  const vp = renderingEngine.getViewport(viewportId);
  const volumeId = `${volumeLoaderScheme}:${volumeName}:${name}`;
  const volume = await volumeLoader.createAndCacheVolume(volumeId, {
    imageIds: imageIds,
  });
  await volume.load();

  setVolumesForViewports(
    renderingEngine,
    [{ volumeId: volumeId }],
    [viewportId]
  );

  setTimeout(() => {
    const voiRange =
      name === "original"
        ? { lower: -1450, upper: 50 }
        : { lower: 0, upper: 255 };
    vp.setProperties({
      voiRange: voiRange,
    });
    vp.render();
  }, 0);
};

//2d 스택 렌더링
export const loadStackImages = async (
  renderingEngine,
  viewportId,
  imageIds,
  name
) => {
  const vp = renderingEngine.getViewport(viewportId);

  // 모든 이미지를 미리 로드하고 캐시에 저장
  const images = await Promise.all(
    imageIds.map(async (imageId) => {
      try {
        const image = await imageLoader.loadAndCacheImage(imageId);
        return image;
      } catch (error) {
        console.error(`Failed to load image ${imageId}:`, error);
        return null;
      }
    })
  );

  if (!imageIds || imageIds.length === 0) {
    throw new Error("imageIds array is empty or undefined.");
  }

  let imageIndex;

  if (imageIds.length < 10) {
    imageIndex = 0;
  } else if (imageIds.length >= 10 && imageIds.length < 60) {
    imageIndex = 11;
  } else if (imageIds.length >= 60) {
    imageIndex = 35;
  }

  // 스택 설정
  await vp.setStack(imageIds, imageIndex);

  //pixel에 따른 voi 값 설정
  const intercept = images[0].intercept || 0;
  const slope = images[0].slope || 1;

  const lowerPixelValue = (lungLowerHU - intercept) / slope;
  const upperPixelValue = (lungUpperHU - intercept) / slope;

  // 초기 Lung 설정 적용
  //vp.setProperties({ voiRange: { lower: -1450, upper: 50 } });
  vp.setProperties({
    voiRange: { lower: lowerPixelValue, upper: upperPixelValue },
  });

  // viewport에 viewportKey란 걸 저장 -> WWWC를 초기화할 때 사용(원본만 lung setting이어야 해서)
  vp.viewportKey = name;

  vp.render();

  // pixelValue 값을 반환
  return { intercept, slope };
};

//2d 스택 렌더링 업데이트
export const updateStackImages = async (
  renderingEngine,
  viewportId,
  imageIds,
  name
) => {
  let ready = false;
  const vp = renderingEngine.getViewport(viewportId);

  const loadedImages = await Promise.all(
    imageIds.map(async (id) => {
      try {
        return await imageLoader.loadAndCacheImage(id);
      } catch (error) {
        console.error(`Failed to load image ${id}:`, error);
        return null;
      }
    })
  );

  const validImageIds = loadedImages
    .filter((img) => img !== null)
    .map((img) => img.imageId);

  if (validImageIds.length === 0) {
    console.error("No valid images to update in stack.");
    return;
  }

  // 첫 번째 이미지에서 Rescale Slope와 Intercept 가져오기
  const intercept = loadedImages[0].intercept;
  const slope = loadedImages[0].slope;

  // HU -> Pixel Value 변환
  const lowerPixelValue = (lungLowerHU - intercept) / slope;
  const upperPixelValue = (lungUpperHU - intercept) / slope;

  let imageIndex;
  if (imageIds.length < 10) {
    imageIndex = 0;
  } else if (imageIds.length >= 10 && imageIds.length < 60) {
    imageIndex = 11;
  } else if (imageIds.length >= 60) {
    imageIndex = 35;
  }
  await vp.setStack(validImageIds, imageIndex);

  // VOI 설정 (name에 따라 다르게 적용)
  if (name === "original") {
    // Original: Lung 범위 (HU -> Pixel Value 변환값 적용)
    vp.setProperties({
      voiRange: {
        lower: lowerPixelValue,
        upper: upperPixelValue,
      },
    });
  } else {
    // 그 외: 기본 0~255 범위 설정
    vp.setProperties({
      voiRange: {
        lower: 0,
        upper: 255,
      },
    });
  }

  // viewport에 viewportKey란 걸 저장 -> WWWC를 초기화할 때 사용(원본만 lung setting이어야 해서)
  vp.viewportKey = name;

  vp.render();
  ready = true;

  return { ready };
};
