import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Layout from "../modules/layout/Layout";
import styled from "styled-components";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import Logo from "../assets/Lucacast_logo_gray.png";
import Lungs from "../assets/lung_cancer.png";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import { Select, Slider, ConfigProvider } from "antd";
import { ViewerResult } from "../api/patientApi.jsx";
import SummaryLang from "../components/summaryLang.jsx";
import { openDB, saveToDB, loadFromDB } from "../modules/indexedDB/idbUtils.js";
import DicomImagePrev from "../modules/dicom/preview/DicomImagePrev.jsx";
import { UserChkToken } from "../api/userApi";
import store from "../store/store";
import { UserDetailsAction } from "../store/action";

const { Option } = Select;

const MainInner = styled.div`
  padding: 0;
  margin: 0;
  width: 100%;
  height: calc(100vh - 63px);
  display: flex;
`;

const Content = styled.div`
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
`;

const SheetContainer = styled.div`
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
`;
const SheetContainerInner = styled.div`
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow-y: auto;
`;

const Sheet = styled.div`
  width: 210mm;
  height: 297mm;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const SheetInner = styled.div`
  padding: 10mm 10mm 0 10mm;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const TitleContainer = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  gap: 10px;
`;
const TitleLogo = styled.div`
  width: 215px;
  height: 30px;
  background-image: url(${Logo});
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
`;
const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 15px 0;

  > h1 {
    margin: 0;
    font-size: 28px;
    font-weight: 800;
  }
`;
const SubtitleContainer = styled.div`
  padding: 5px 10px;
  margin: 0;
  display: flex;
  border-top: 2px solid #b7b7b7;
  border-bottom: 2px solid #b7b7b7;
  margin-bottom: 10px;
`;
const Subtitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;

  > h1 {
    margin: 0;
    font-size: 20px;
    font-weight: 700;
  }
`;
const PatientInfoContainer = styled.div`
  padding: 10px;
  margin: 0;
  display: flex;
  border-radius: 4px;
  background: #eeeeee;
  margin-bottom: 10px;
`;
const Column = styled.div`
  width: 100%;
`;
const PatientInfo = styled.div`
  padding: 5px;
  font-size: 15px;

  > p {
    margin: 0;
    font-size: 15px;
    font-weight: 400;

    > span {
      font-weight: 700;
    }
  }
`;
const PredictionContainers = styled.div`
  padding: 10px;
  margin: 0;
  display: flex;
`;
const GraphContainers = styled.div`
  padding: 0;
  margin: 0;
  width: 50%;
  display: flex;
  flex-direction: column;
`;
const DoughnutContainer = styled.div`
  padding: 0px;
  margin: 0;
  display: flex;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
`;

const LungImage = styled.img`
  width: 80px;
  position: absolute;
  left: 77px;
  top: 28px;
`;
const SliderContainer = styled.div`
  padding: 0 10px 10px 10px;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ThresholdText = styled.div`
  font-size: 16px;
  font-weight: 600;
`;
const ResultsContainers = styled.div`
  padding: 35px 0;
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ImageContainers = styled.div`
  margin: 0 0 10px 0;
  display: flex;
  justify-content: space-around;
`;

const Notes = styled.div`
  width: 100%;
  height: 250px;
  border-radius: 4px;
  background: #eeeeee;
  padding: 15px;
  box-sizing: border-box;
  font-size: 16px;
`;
const FooterLogoContainer = styled.div`
  padding: 5mm 10mm;
  margin: 0;
  height: auto;
  width: auto;
`;
const FooterLogo = styled.div`
  width: 100px;
  height: 15px;
  background-image: url(${Logo});
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
`;

const SidebarContainer = styled.div`
  padding: 0px;
  margin: 0;
  width: auto;
  min-width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #212121;
`;
const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 0;
  padding: 10px 12px;
  gap: 0.5rem;
  box-sizing: border-box;
  border-top: 10px solid #000;
  border-bottom: 10px solid #000;
  padding: 22px 10px;
`;
const Button = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 5px;
  border-radius: 6px;
  border: solid 1px #747474;
  cursor: pointer;
  color: #fff;
  width: 30%;

  &:hover {
    border: solid 1px #4bf2cc;
    color: #4bf2cc;
  }
`;
const AnalysisButton = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 5px;
  border-radius: 6px;
  border: solid 1px #747474;
  cursor: not-allowed;
  color: #747474;
  width: 30%;
`;
const ButtonText = styled.div`
  font-family: Pretendard;
  font-size: 16.5px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
`;
const LangContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding: 10px 12px;
  gap: 10px;
  box-sizing: border-box;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    color: #fff;

    > div {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    > h1 {
      margin: 0;
      font-size: 16.5px;
      font-weight: 300;
      color: #4bf2cc;
    }
  }
`;

const SaveBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  border-radius: 6px;
  border: solid 1px #fff;
  cursor: pointer;

  > p {
    margin: 0;
    font-size: 13px;
    font-weight: 300;
    color: #fff;
  }
`;

const CustomSlider = styled(Slider)`
  width: 150px;

  &.highMean .ant-slider.ant-slider-disabled,
  &.lowMean .ant-slider.ant-slider-disabled,
  &:where(
      .css-dev-only-do-not-override-1t7o01q
    ).ant-slider.ant-slider-disabled {
    cursor: default !important;
  }

  &.highMean .ant-slider-handle::after {
    background-color: #f53e3e;
    cursor: default;
    width: 10px;
    height: 10px;
    box-shadow: 0 0 0 2px #f53e3e;
    inset-inline-start: 0;
    inset-block-start: 0;
  }

  &.lowMean .ant-slider-handle::after {
    background-color: #30a2df;
    cursor: default;
    width: 10px;
    height: 10px;
    box-shadow: 0 0 0 2px #30a2df;
    inset-inline-start: 0;
    inset-block-start: 0;
  }

  &:where(.css-dev-only-do-not-override-nt11eq).ant-slider.ant-slider-disabled {
    cursor: default;
  }
`;

const TextArea = styled.textarea`
  display: flex;
  width: 100%;
  height: 500px;
  background: #4b4b51;
  border-radius: 4px;
  border: none;
  resize: none;
  padding: 10px;
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 300;
  color: #fff;
  box-sizing: border-box;

  &:focus-visible {
    outline: none;
  }
`;

const ResultTextBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ResultText = styled.div`
  display: flex;
  width: 400px;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 10px;

  > h1 {
    margin: 0;
    font-size: 28px;
    font-weight: 700;
    text-align: center;
  }

  > h2 {
    margin: 0;
    font-size: 32px;
    font-weight: 700;
    text-align: center;
  }
`;

const StyledSelect = styled(Select)`
  width: 100px;
  height: 25px;

  .ant-select-selector {
    background-color: #4b4b51 !important;
    color: #fff !important;
    border: none !important;
    box-shadow: none !important;

    &:focus,
    &:active {
      box-shadow: none !important;
      outline: 0 !important;
    }
  }
  .ant-select-arrow {
    color: #fff !important;
  }
`;

const MAX_CHARACTERS_PER_LINE = 49;
const MAX_LINES = 12;

const Summary = () => {
  const userName = useSelector((state) => state.UserDetailsReducer.userName);
  const storeSummaryParams = useSelector(
    (state) => state.SummaryDataReducer.threshold
  );
  const location = useLocation();
  const navigate = useNavigate();
  const printRef = useRef();
  const { t, i18n } = useTranslation();
  const { user_seq, patient_seq } = useParams();

  const [viewerResultData, setViewerResultData] = useState([]);

  //viewerResultData.mean를 소수점 2자리까지 보여주도록
  const lucaResult = parseFloat((viewerResultData.mean * 100).toFixed(2));

  const [threshold, setThreshold] = useState();

  const [notes, setNotes] = useState(""); // 상태 추가
  const [defaultNotes, setDefaultNotes] = useState(""); // 디폴트 notes 상태 추가
  const [savedNotes, setSavedNotes] = useState(""); // IndexedDB에서 로드한 notes 상태 추가
  const [selectedNotes, setSelectedNotes] = useState("default"); // notes 선택 상태 추가
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language); // 현재 언어 상태 추가
  const [riskLevel, setRiskLevel] = useState(""); // lowRisk/highRisk 상태 추가

  //중복 로그인 방지 처리
  const checkDuplicateLogin = async () => {
    if (!userName) return; // userName이 없으면 함수 종료
    const params = { user_id: userName };
    if (location.pathname !== "/") {
      const localStorageTokenString = localStorage.getItem("luca_userDetails");
      if (!localStorageTokenString) {
        console.error("localStorageToken is null");
        return;
      }
      const localStorageToken = JSON.parse(localStorageTokenString);
      try {
        const serverToken = await UserChkToken(params);
        if (localStorageToken.token !== serverToken[0].token) {
          store.dispatch(UserDetailsAction(null));
          localStorage.removeItem("luca_userDetails");
          navigate(`/`);
        }
      } catch (error) {
        console.error("Error during checkDuplicateLogin:", error);
      }
    }
  };

  checkDuplicateLogin();

  const fetchData = async () => {
    const params = {
      user_seq: user_seq,
      patient_seq: patient_seq,
    };
    try {
      const data = await ViewerResult(params);
      const viewerResult_data = data.data[0];
      setViewerResultData(viewerResult_data);
      console.log("viewerResult_data", viewerResult_data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (storeSummaryParams) {
      setThreshold(parseFloat(storeSummaryParams).toFixed(2));
    } else {
      setThreshold(parseFloat(3).toFixed(2));
    }
  }, [storeSummaryParams]);

  useEffect(() => {
    if (lucaResult !== undefined) {
      const initialRiskLevel = lucaResult >= threshold ? "highRisk" : "lowRisk";
      const initialNotes =
        initialRiskLevel === "highRisk" ? t("highRisk") : t("lowRisk");
      setRiskLevel(initialRiskLevel);
      setDefaultNotes(initialNotes);
      setNotes(initialNotes); // 디폴트 값으로 설정
    }
  }, [viewerResultData, threshold, t]);

  useEffect(() => {
    // IndexedDB에서 notes 로드 및 defaultValue 설정
    const loadNotes = async () => {
      try {
        const db = await openDB("SummaryDB", "NotesStore");
        const result = await loadFromDB(
          db,
          "NotesStore",
          `${userName}_${currentLanguage}_${riskLevel}`
        );
        if (result) {
          setSavedNotes(result.notes);
          setSelectedNotes("saved");
          setNotes(result.notes);
        } else {
          setSelectedNotes("default");
          setNotes(defaultNotes);
        }
      } catch (error) {
        console.log("Failed to load notes:", error);
        setSelectedNotes("default");
      }
    };

    if (riskLevel) {
      loadNotes();
    }
  }, [userName, currentLanguage, riskLevel, defaultNotes]);

  const handleSave = async () => {
    try {
      const db = await openDB("SummaryDB", "NotesStore");
      await saveToDB(db, "NotesStore", {
        id: `${userName}_${currentLanguage}_${riskLevel}`,
        notes,
      });
      alert("Notes saved successfully");
    } catch (error) {
      console.log("Failed to save notes:", error);
    }
  };

  const handleNotesChange = (e) => {
    const { value } = e.target;
    const lines = value.split("\n");
    let totalLines = 0;

    for (let line of lines) {
      const lineCount = Math.ceil(line.length / MAX_CHARACTERS_PER_LINE);
      totalLines += lineCount > 0 ? lineCount : 1; // 빈 줄도 1줄로 카운트
    }

    if (totalLines <= MAX_LINES) {
      setNotes(value);
    }
  };

  const handleNotesSelectionChange = (value) => {
    setSelectedNotes(value);
    if (value === "default") {
      setNotes(defaultNotes);
    } else if (value === "saved" && savedNotes) {
      setNotes(savedNotes);
    }
  };

  const handleLanguageChange = (language) => {
    setCurrentLanguage(language);
  };

  const convertNewlinesToBreaks = (text) => {
    return text.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  const handlePrint = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element, {
      useCORS: true,
      allowTaint: true,
    });
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF("p", "mm", "a4");
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    const pdfBlob = pdf.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl);
  };

  const validateValue = (value) => {
    return isNaN(value) ? 0 : value;
  };

  return (
    <Layout user_seq={user_seq} patient_seq={patient_seq}>
      <MainInner>
        <DndProvider backend={HTML5Backend}>
          <Content>
            <SheetContainer>
              <SheetContainerInner>
                <Sheet ref={printRef}>
                  <SheetInner>
                    <TitleContainer>
                      <Title>
                        <h1>{t("summaryTitle")}</h1>
                      </Title>
                      <TitleLogo />
                    </TitleContainer>
                    <SubtitleContainer>
                      <Subtitle>
                        <h1>{t("patientInfo")}</h1>
                      </Subtitle>
                    </SubtitleContainer>
                    <PatientInfoContainer>
                      <Column>
                        <PatientInfo>
                          <p>
                            <span>{t("name_ID")} : </span>{" "}
                            {viewerResultData.patientName}(
                            {viewerResultData.patientID})
                          </p>
                        </PatientInfo>
                        <PatientInfo>
                          <p>
                            <span>{t("sex")} : </span>
                            {viewerResultData.gender === "F"
                              ? "Female"
                              : "Male"}
                          </p>
                        </PatientInfo>
                        <PatientInfo>
                          <p>
                            <span>{t("age")} : </span>
                            {viewerResultData.age}
                          </p>
                        </PatientInfo>
                      </Column>
                      <Column>
                        <PatientInfo>
                          <p>
                            <span>{t("studyDescription")} : </span>
                            {viewerResultData.studyDescription}
                          </p>
                        </PatientInfo>
                        <PatientInfo>
                          <p>
                            <span>{t("studyDate")} : </span>
                            {viewerResultData.studyDate
                              ? viewerResultData.studyDate.replace(
                                  /(\d{4})(\d{2})(\d{2})/,
                                  "$1-$2-$3"
                                )
                              : "N/A"}
                          </p>
                        </PatientInfo>
                        <PatientInfo>
                          <p>
                            <span>{t("analysisDate")} : </span>
                            {viewerResultData.reg_date_AI
                              ? viewerResultData.reg_date_AI.split("T")[0]
                              : "N/A"}
                          </p>
                        </PatientInfo>
                      </Column>
                    </PatientInfoContainer>
                    <SubtitleContainer>
                      <Subtitle>
                        <h1>{t("lucaImg")}</h1>
                      </Subtitle>
                    </SubtitleContainer>

                    <ImageContainers>
                      <DicomImagePrev
                        StudyInstanceUID={viewerResultData.studyInstanceUID}
                        SeriesInstanceUID={viewerResultData.seriesInstanceUID}
                      />
                      <DicomImagePrev
                        StudyInstanceUID={viewerResultData.studyUID_1}
                        SeriesInstanceUID={viewerResultData.seriesUID_1}
                      />
                      <DicomImagePrev
                        StudyInstanceUID={viewerResultData.studyUID_2}
                        SeriesInstanceUID={viewerResultData.seriesUID_2}
                      />
                      <DicomImagePrev
                        StudyInstanceUID={viewerResultData.studyUID_3}
                        SeriesInstanceUID={viewerResultData.seriesUID_3}
                      />
                    </ImageContainers>
                    <SubtitleContainer>
                      <Subtitle>
                        <h1>{t("predictionResult")}</h1>
                      </Subtitle>
                    </SubtitleContainer>
                    <PredictionContainers>
                      <GraphContainers>
                        <DoughnutContainer>
                          <LungImage src={Lungs} />
                          <Gauge
                            value={validateValue(lucaResult)}
                            startAngle={0}
                            endAngle={360}
                            innerRadius="80%"
                            outerRadius="100%"
                            width={160}
                            height={160}
                            text={({ value }) => `${value}%`}
                            sx={(theme) => ({
                              [`& .${gaugeClasses.valueText}`]: {
                                fontSize: 30,
                                fontWeight: 700,
                              },
                              [`& .${gaugeClasses.valueText} > text`]: {
                                fill:
                                  lucaResult >= threshold
                                    ? "#e70000"
                                    : "#136b9a",
                              },
                              [`& .${gaugeClasses.valueArc}`]: {
                                fill:
                                  lucaResult >= threshold
                                    ? "#F53E3E"
                                    : "#4bb8f2",
                              },
                              [`& .${gaugeClasses.referenceArc}`]: {
                                fill: "#b7b7b7",
                              },
                            })}
                          />
                        </DoughnutContainer>
                        <SliderContainer>
                          <ConfigProvider
                            theme={{
                              components: {
                                Slider: {
                                  trackBgDisabled:
                                    lucaResult >= threshold
                                      ? "#F53E3E"
                                      : "#30a2df",
                                  railBg:
                                    lucaResult >= threshold
                                      ? "#ffc9c9"
                                      : "#a3c8dc ",
                                },
                              },
                            }}
                          >
                            <CustomSlider
                              className={
                                lucaResult >= threshold ? "highMean" : "lowMean"
                              }
                              value={threshold}
                              disabled={true}
                              tooltip={{ open: false }}
                            />
                          </ConfigProvider>

                          <ThresholdText>
                            {t("threshold")}: {threshold}%
                          </ThresholdText>
                        </SliderContainer>
                      </GraphContainers>
                      <ResultsContainers>
                        <ResultTextBox>
                          <ResultText>
                            <h1>{t("predictionResult1")}</h1>
                            <h2
                              style={{
                                color:
                                  lucaResult >= threshold
                                    ? "#F53E3E"
                                    : "#30a2df",
                              }}
                            >
                              {lucaResult}%
                            </h2>
                            <h1>{t("predictionResult2")}</h1>
                            <h1>{t("predictionResult3")}</h1>
                          </ResultText>
                          <div></div>
                        </ResultTextBox>
                      </ResultsContainers>
                    </PredictionContainers>
                    <SubtitleContainer>
                      <Subtitle>
                        <h1>{t("Notes")}</h1>
                      </Subtitle>
                    </SubtitleContainer>
                    <Notes>{convertNewlinesToBreaks(notes)}</Notes>
                  </SheetInner>
                  <FooterLogoContainer>
                    <FooterLogo />
                  </FooterLogoContainer>
                </Sheet>
              </SheetContainerInner>
            </SheetContainer>
            <SidebarContainer>
              <ButtonContainer>
                <AnalysisButton>
                  <ButtonText>Analysis</ButtonText>
                </AnalysisButton>
                <Button onClick={handlePrint}>
                  <ButtonText>Print</ButtonText>
                </Button>
                <Button onClick={() => handleNavigation("/PatientList")}>
                  <ButtonText>Exit</ButtonText>
                </Button>
              </ButtonContainer>
              <LangContainer>
                <div>
                  <h1>Type</h1>
                </div>
                <div>
                  <SummaryLang onChangeLanguage={handleLanguageChange} />
                </div>
              </LangContainer>
              <LangContainer>
                <div>
                  <h1>
                    Notes
                    <span style={{ color: "#fff", fontSize: 13 }}>
                      {" "}
                      (공백 포함 580자)
                    </span>
                  </h1>
                  <div>
                    <ConfigProvider
                      theme={{
                        components: {
                          Select: {
                            color: "#fff",
                            optionSelectedBg: "#39393e",
                            optionHoverBg: "#39393e",
                            optionSelectedColor: "#fff",
                            fontSize: 12,
                          },
                        },
                      }}
                    >
                      <StyledSelect
                        onChange={handleNotesSelectionChange}
                        dropdownStyle={{ backgroundColor: "#4b4b51" }}
                        value={selectedNotes}
                      >
                        <Option style={{ color: "#fff" }} value="default">
                          default
                        </Option>
                        <Option style={{ color: "#fff" }} value="saved">
                          Custom
                        </Option>
                      </StyledSelect>
                    </ConfigProvider>
                    <SaveBtn onClick={handleSave}>
                      <p>Save</p>
                    </SaveBtn>
                  </div>
                </div>
                <div>
                  <TextArea
                    id="notes"
                    name="notes"
                    value={notes}
                    onChange={handleNotesChange}
                  />
                </div>
              </LangContainer>
            </SidebarContainer>
          </Content>
        </DndProvider>
      </MainInner>
    </Layout>
  );
};

export default Summary;
