import React from "react";
import styled from "styled-components";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 33%;
`;
const Left = styled.div`
  display: flex;
`;

const DoughnutChart = (props) => {
  return (
    <React.Fragment>
      <Container>
        <Left>
          <Gauge
            value={props.risk}
            startAngle={0}
            endAngle={360}
            innerRadius="80%"
            outerRadius="100%"
            width={130}
            height={130}
            text={({ value }) => `${value} %`}
            sx={(theme) => ({
              [`& .${gaugeClasses.valueText}`]: {
                fontSize: 20,
              },
              [`& .${gaugeClasses.valueText} > text`]: {
                fill: "#fff",
              },
              [`& .${gaugeClasses.valueArc}`]: {
                fill: props.risk >= props.threshold ? "#F53E3E" : "#4bb8f2",
              },
              [`& .${gaugeClasses.referenceArc}`]: {
                fill: props.risk >= props.threshold ? "#822e2e" : "#2c6685",
              },
            })}
          />
        </Left>
      </Container>
    </React.Fragment>
  );
};
export default DoughnutChart;
