import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { FileImageOutlined } from "@ant-design/icons";

const { REACT_APP_ORTHANC_URL } = process.env;

const Container = styled.div`
  padding: 10px;
  margin: 5px 0;
  width: 160px;
  height: 160px;
  display: flex;
  align-items: center;
`;

const LeftContainer = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
`;

const ErrorBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  > p {
    font-size: 14px;
    text-align: center;
  }
`;

const DicomImagePrev = (props) => {
  const [imageSrc, setImageSrc] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDicomImage = async () => {
      try {
        setError(null);
        const ORTHANC_BASE_URL = `http://${REACT_APP_ORTHANC_URL}/orthancImg`;

        // Study 목록을 가져와 StudyInstanceUID를 찾습니다.
        const allStudiesResponse = await axios.get(
          `${ORTHANC_BASE_URL}/studies`
        );
        const allStudies = allStudiesResponse.data;

        let studyUUID = null;
        for (const study of allStudies) {
          const studyDetailsResponse = await axios.get(
            `${ORTHANC_BASE_URL}/studies/${study}`
          );
          const studyDetails = studyDetailsResponse.data;
          if (
            studyDetails.MainDicomTags.StudyInstanceUID ===
            props.StudyInstanceUID
          ) {
            studyUUID = study;
            break;
          }
        }

        if (!studyUUID) {
          throw new Error("StudyInstanceUID not found");
        }

        // Series 목록에서 SeriesInstanceUID를 찾습니다.
        const seriesResponse = await axios.get(
          `${ORTHANC_BASE_URL}/studies/${studyUUID}/series`
        );
        const seriesList = seriesResponse.data;

        let seriesUUID = null;
        for (const series of seriesList) {
          const seriesDetailsResponse = await axios.get(
            `${ORTHANC_BASE_URL}/series/${series.ID}`
          );
          const seriesDetails = seriesDetailsResponse.data;
          if (
            seriesDetails.MainDicomTags.SeriesInstanceUID ===
            props.SeriesInstanceUID
          ) {
            seriesUUID = series.ID;
            break;
          }
        }

        if (!seriesUUID) {
          throw new Error("SeriesInstanceUID not found");
        }

        // 인스턴스를 가져와 InstanceNumber가 35인 타겟 인스턴스를 찾습니다.
        const instancesResponse = await axios.get(
          `${ORTHANC_BASE_URL}/series/${seriesUUID}/instances`
        );
        const instancesList = instancesResponse.data;

        const targetInstance = instancesList.find(
          (instance) => instance.MainDicomTags.InstanceNumber === "35"
        );

        if (!targetInstance) {
          throw new Error("Instance number 35 not found");
        }

        // preview 이미지 URL을 바로 설정
        const imageUrl = `${ORTHANC_BASE_URL}/instances/${targetInstance.ID}/preview`;
        setImageSrc(imageUrl);
      } catch (error) {
        console.error("Error fetching DICOM image:", error);
        setError(error.message);
      }
    };

    if (props.StudyInstanceUID && props.SeriesInstanceUID) {
      fetchDicomImage();
    } else {
      console.log("StudyInstanceUID or SeriesInstanceUID is missing");
    }
  }, [props.StudyInstanceUID, props.SeriesInstanceUID]);

  return (
    <Container>
      <LeftContainer>
        {error && (
          <ErrorBox>
            <FileImageOutlined style={{ fontSize: "50px" }} />
            <p>An error occurred while loading the image.</p>
          </ErrorBox>
        )}
        {imageSrc && (
          <img
            src={imageSrc}
            alt="DICOM"
            style={{ width: "100%", height: "100%" }}
          />
        )}
      </LeftContainer>
    </Container>
  );
};

export default DicomImagePrev;
