import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDrag } from "react-dnd";
import { ItemTypes } from "./ItemTypes.js";
import { getRenderingEngine } from "@cornerstonejs/core";
import createImageIdsAndCacheMetaData_STACK from "./utils/helper/createImageIdsAndCacheMetaData_STACK";
import { updateVolume, updateStackImages } from "./loadVolume.js";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import { FileImageOutlined } from "@ant-design/icons";

const { REACT_APP_ORTHANC_URL } = process.env;

const Container = styled.div`
  position: relative;
  padding: 10px;
  margin: 5px 0;
  width: auto;
  height: auto;
  display: flex;
  border-radius: 8px;
  background: #2a2a2c;
  cursor: ${({ $isProcessing }) => ($isProcessing ? "not-allowed" : "grab")};
  border: solid 1px #2a2a2c;
  &:hover {
    border: solid 1px
      ${({ $isProcessing }) => ($isProcessing ? "#2a2a2c" : "#45ffca")};
  }
  align-items: center;
`;

const LeftContainer = styled.div`
  width: 57px;
  height: 57px;
  background-size: cover;
  background-position: center;
`;

const RightContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  margin: 0;
  padding: 0 5px 0 20px;
  width: 80%;
`;

const DicomNameBox = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  > div {
    display: flex;

    > p {
      font-size: 16px;
      color: #fff;
      margin: 0;
    }
  }
`;

const AIBox = styled.div`
  display: flex;
  padding: 1px 15px;
  border-radius: 5px;
  border: solid 1px #747474;

  > h1 {
    font-size: 14px;
    font-weight: 400;
    color: #45ffca;
    margin: 0;
  }
`;

const NoteContainer = styled.div`
  display: flex;

  > div {
    display: flex;
    align-items: center;
    gap: 10px;

    > h2 {
      font-size: 14px;
      font-weight: 400;
      color: #fff;
      margin: 0;
    }
    > h3 {
      font-size: 14px;
      font-weight: 400;
      color: #45ffca;
      margin: 0;
    }
  }
`;

const LoadingOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
  top: 0;
  left: 0;
  border-radius: 8px;
`;

const ErrorBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;

  > p {
    font-size: 10px;
    text-align: center;
    margin: 0;
    color: #a3a3a3;
  }
`;

const DicomList = (props) => {
  const [imageSrc, setImageSrc] = useState("");
  const [error, setError] = useState(null);

  const fetchDicomImage = async () => {
    try {
      setError(null);
      const ORTHANC_BASE_URL = `http://${REACT_APP_ORTHANC_URL}/orthancImg`;

      // Study 목록을 가져와 StudyInstanceUID를 찾습니다.
      const allStudiesResponse = await axios.get(`${ORTHANC_BASE_URL}/studies`);
      const allStudies = allStudiesResponse.data;

      let studyUUID = null;
      for (const study of allStudies) {
        const studyDetailsResponse = await axios.get(
          `${ORTHANC_BASE_URL}/studies/${study}`
        );
        const studyDetails = studyDetailsResponse.data;
        if (
          studyDetails.MainDicomTags.StudyInstanceUID === props.StudyInstanceUID
        ) {
          studyUUID = study;
          break;
        }
      }

      if (!studyUUID) {
        throw new Error("StudyInstanceUID not found");
      }

      // Series 목록에서 SeriesInstanceUID를 찾습니다.
      const seriesResponse = await axios.get(
        `${ORTHANC_BASE_URL}/studies/${studyUUID}/series`
      );
      const seriesList = seriesResponse.data;

      let seriesUUID = null;
      for (const series of seriesList) {
        const seriesDetailsResponse = await axios.get(
          `${ORTHANC_BASE_URL}/series/${series.ID}`
        );
        const seriesDetails = seriesDetailsResponse.data;
        if (
          seriesDetails.MainDicomTags.SeriesInstanceUID ===
          props.SeriesInstanceUID
        ) {
          seriesUUID = series.ID;
          break;
        }
      }

      if (!seriesUUID) {
        throw new Error("SeriesInstanceUID not found");
      }

      // 인스턴스를 가져와 InstanceNumber가 35인 타겟 인스턴스를 찾습니다.
      const instancesResponse = await axios.get(
        `${ORTHANC_BASE_URL}/series/${seriesUUID}/instances`
      );
      const instancesList = instancesResponse.data;

      const targetInstance = instancesList.find(
        (instance) => instance.MainDicomTags.InstanceNumber === "35"
      );

      if (!targetInstance) {
        throw new Error("Instance number 35 not found");
      }

      // 이미지 URL을 바로 설정
      const imageUrl = `${ORTHANC_BASE_URL}/instances/${targetInstance.ID}/preview`;
      setImageSrc(imageUrl);
    } catch (error) {
      console.error("Error fetching DICOM image:", error);
      setError(error.message);
    }
  };

  useEffect(() => {
    if (props.StudyInstanceUID && props.SeriesInstanceUID) {
      fetchDicomImage();
    }
  }, [props.StudyInstanceUID, props.SeriesInstanceUID]);

  useEffect(() => {
    const autoLoadDicom = async () => {
      if (props.viewportId === "port-1") return;

      if (props.StudyInstanceUID && props.SeriesInstanceUID) {
        const dropResult = {
          renderEngineId: "LucaRenderingEngine",
          id: null,
        };

        switch (props.viewportId) {
          case "port-2":
            dropResult.id = "12";
            break;
          case "port-3":
            dropResult.id = "21";
            break;
          case "port-4":
            dropResult.id = "22";
            break;
          default:
            console.error("Viewport ID not found.");
            return;
        }

        // DICOM 메타데이터 병렬 요청
        const imageIds = await createImageIdsAndCacheMetaData_STACK({
          StudyInstanceUID: props.StudyInstanceUID,
          SeriesInstanceUID: props.SeriesInstanceUID,
          wadoRsRoot: `http://${REACT_APP_ORTHANC_URL}/orthanc`,
        });

        // 렌더링 엔진 유효성 검사 최소화 및 로드
        let renderingEngine = getRenderingEngine(dropResult.renderEngineId);
        if (!renderingEngine) {
          console.error("Rendering engine not found.");
          return;
        }

        // 병렬 업데이트 및 준비 완료 콜백
        // await Promise.all([
        //   // updateVolume(renderingEngine, dropResult.id, imageIds, props.name),
        //   updateStackImages(
        //     renderingEngine,
        //     dropResult.id,
        //     imageIds,
        //     props.name
        //   ),
        //   props.onReady(),
        // ]);
        const { ready } = await updateStackImages(
          renderingEngine,
          dropResult.id,
          imageIds,
          props.name
        );
        if (ready) {
          props.onReady();
        }
      }
    };

    // 조건: 렌더링 엔진이 준비된 후 이미지를 로드
    if (!props.isProcessing && props.isReady) {
      autoLoadDicom();
    }
  }, [props.isReady, props.StudyInstanceUID, props.SeriesInstanceUID]);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.DICOM,
      item: { ...props },
      canDrag: () => !props.isProcessing,
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult();
        if (item && dropResult) {
          props.setIsProcessing(true);
          props.onDragStart(item.viewportId);

          const run = async (dropResult) => {
            const renderingEngine = getRenderingEngine(
              dropResult.renderEngineId
            );

            const imageIds = await createImageIdsAndCacheMetaData_STACK({
              StudyInstanceUID: props.StudyInstanceUID,
              SeriesInstanceUID: props.SeriesInstanceUID,
              //wadoRsRoot: "http://localhost/orthanc",
              wadoRsRoot: `http://${REACT_APP_ORTHANC_URL}/orthanc`,
            });

            // await updateVolume(
            //   renderingEngine,
            //   dropResult.id,
            //   imageIds,
            //   props.name
            // );
            await updateStackImages(
              renderingEngine,
              dropResult.id,
              imageIds,
              props.name
            );

            props.setIsProcessing(false);
            props.onDragEnd();
          };

          run(dropResult);
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    }),
    [props.isProcessing]
  );

  return (
    <Container ref={drag} $isProcessing={props.isProcessing}>
      {props.isProcessing && props.activeDragItem === props.viewportId && (
        <LoadingOverlay>
          <LoadingOutlined
            style={{
              color: "#fff",
              fontSize: "36px",
            }}
          />
        </LoadingOverlay>
      )}
      {props.isProcessing && props.activeDragItem !== props.viewportId && (
        <LoadingOverlay></LoadingOverlay>
      )}
      <LeftContainer>
        {imageSrc ? (
          <img
            src={imageSrc}
            alt="DICOM"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "3px",
            }}
          />
        ) : (
          <ErrorBox>
            <FileImageOutlined style={{ fontSize: "40px", color: "#a3a3a3" }} />
            <p>Load Error</p>
          </ErrorBox>
        )}
      </LeftContainer>
      <RightContainer>
        <DicomNameBox>
          <div>
            <p>{props.name}</p>
          </div>
          {props.name !== "Original" && (
            <AIBox>
              <h1>AI</h1>
            </AIBox>
          )}
        </DicomNameBox>
        <NoteContainer>
          <div>
            <h2>note</h2>
            <h3>{props.note}</h3>
          </div>
        </NoteContainer>
      </RightContainer>
    </Container>
  );
};

export default DicomList;
