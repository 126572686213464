import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Modal, Button, message } from "antd";
import RefreshUpload from "./RefreshUpload";
import * as dicomParser from "dicom-parser";
import { Patients_ConnectAI } from "../../api/connectAI";
import { MultiInsertPatient } from "../../api/insertPatientApi";

const { REACT_APP_ORTHANC_URL2 } = process.env;

const PatientModal = styled(Modal)`
  .ant-modal-content {
    background-color: #303030;
    color: #fefefe;
  }

  .ant-modal-title {
    display: flex;
    justify-content: center;
    background-color: #303030;
    color: #fefefe;
    font-size: 1.5rem;
    font-weight: 600;
  }

  .ant-modal-close-x {
    color: #fefefe;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  span {
    font-family: "Pretendard";
    margin: 0;
  }

  input,
  label,
  button {
    font-family: "Pretendard";
  }
`;

const SaveBtn = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.3rem 3rem;
  border-radius: 0.5rem;
  border: solid 0.1rem #4bf2cc;
  background-color: #303030;
  font-size: 1rem;
  font-weight: 500;
  color: #4bf2cc;

  .ant-btn-loading-icon {
    color: #4bf2cc;
  }

  &:hover {
    border-color: #4bf2cc !important;
    color: #4bf2cc !important;
    background: #303030 !important;
  }

  &:disabled {
    border-color: #6e6e6e !important;
    color: #6e6e6e !important;
  }
`;

const PatientModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
  gap: 1rem;
  padding: 1.5rem 0;
  border-bottom: solid 0.1rem #747474;
`;

const InputBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1rem;

  > p {
    width: 40%;
    font-size: 1rem;
    font-weight: 500;
  }

  > input[type="text"] {
    width: 60%;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border: solid 0.1rem #969696;
    border-radius: 0.5rem;
    background-color: #747474;
    color: #cccccc;
  }
  > input[type="text"]::placeholder {
    color: #989898;
  }

  > div {
    display: flex;
    width: 85%;

    > label {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      padding: 0.5rem 1rem;
      font-size: 1rem;
      border: solid 0.1rem #969696;
      background-color: #747474;
      color: #fefefe;
      cursor: pointer;
    }
  }
`;

const DicomUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
  gap: 1rem;

  > h1 {
    font-size: 1rem;
    font-weight: 500;
  }
`;

const RefreshModal = ({ open, setIsRefreshModalOpen, setIsRefresh }) => {
  const userName = useSelector((state) => state.UserDetailsReducer.userName);
  const [selectedFolderPath, setSelectedFolderPath] = useState(""); // 선택된 경로
  const [folderStructure, setFolderStructure] = useState({}); // 폴더-파일 구조
  const [folderStatus, setFolderStatus] = useState({}); // 폴더별 상태 (pass, params_error, dcm_error)
  const [confirmLoading, setConfirmLoading] = useState(false);

  // "pass" 상태의 폴더가 있는지 확인
  const hasPassFolders = useMemo(() => {
    return Object.values(folderStatus).includes("pass");
  }, [folderStatus]);

  const handleCancel = () => {
    setSelectedFolderPath(""); // 선택된 경로 초기화
    setFolderStructure({}); // 폴더-파일 구조 초기화
    setFolderStatus({}); // 폴더별 상태 초기화

    setIsRefreshModalOpen(false); // 모달 닫기
  };

  const handleFolderPathChange = (folderPathName) => {
    setSelectedFolderPath(folderPathName); // 자식 컴포넌트에서 전달받은 폴더 경로 저장
  };

  const handleFolderDataChange = (structure, status) => {
    setFolderStructure(structure); // 폴더 구조 업데이트
    setFolderStatus(status); // 폴더 상태 업데이트
  };

  const handleSave = async () => {
    setConfirmLoading(true);
    const orthancServerUrl = `http://${REACT_APP_ORTHANC_URL2}:8042/instances`;

    try {
      // "pass" 상태의 폴더만 필터링
      const passFolders = Object.entries(folderStatus)
        .filter(([_, status]) => status === "pass")
        .map(([folderName]) => folderName);

      if (passFolders.length === 0) {
        message.destroy();
        message.warning("No valid folders to upload");
        return;
      }

      // 각 "pass" 폴더에 대해 요청
      for (const folderName of passFolders) {
        const files = folderStructure[folderName]; // 해당 폴더의 파일들
        const params = {}; // 해당 폴더의 메타데이터를 저장

        // 첫 번째 파일의 메타데이터를 추출 (모든 파일의 메타데이터가 동일하다고 가정)
        const firstFileBuffer = await files[0].arrayBuffer();
        const dataSet = dicomParser.parseDicom(new Uint8Array(firstFileBuffer));

        params.userPath = selectedFolderPath;
        params.folderName = folderName;
        params.patientName = dataSet.string("x00100010");
        params.age = dataSet
          .string("x00101010")
          .replace(/[^\d]/g, "")
          .replace(/^0+/, "");
        params.gender = ["M", "F"].includes(dataSet.string("x00100040"))
          ? dataSet.string("x00100040")
          : "";
        params.patientID = dataSet.string("x00100020");
        params.section = `${dataSet.string("x00080060")}-${dataSet.string(
          "x00180015"
        )}`;
        params.seriesInstanceUID = dataSet.string("x0020000e");
        params.studyInstanceUID = dataSet.string("x0020000d");
        params.seriesDate = dataSet.string("x00080021");
        params.studyDate = dataSet.string("x00080020");
        params.studyDescription = dataSet.string("x00081030");
        params.user_id = userName;

        // Insert 요청
        const insertRes = await MultiInsertPatient(params);

        // Orthanc 서버에 파일 업로드
        const formData = new FormData();
        files.forEach((file) => {
          formData.append("file", file);
        });

        await fetch(orthancServerUrl, {
          method: "POST",
          body: formData,
          headers: {
            Accept: "application/json",
          },
          mode: "no-cors",
        });

        message.success(`Successfully uploaded folder: ${folderName}`);
      }
      setIsRefreshModalOpen(false);
      setIsRefresh(true);
    } catch (error) {
      message.error("Failed to upload folders");
      console.error(error);
    } finally {
      setConfirmLoading(false);
    }
  };

  return (
    <>
      <PatientModal
        title="Multi Upload"
        onCancel={handleCancel}
        open={open}
        footer={null}
      >
        <PatientModalContent>
          <InputContainer>
            <InputBox>
              <p>Selected Folder</p>
              <input
                type="text"
                value={selectedFolderPath}
                placeholder="Selected Folder Name"
                disabled
              />
            </InputBox>
          </InputContainer>
          <DicomUploadContainer>
            <RefreshUpload
              onFolderPathChange={handleFolderPathChange}
              onFolderDataChange={handleFolderDataChange}
              folderStructure={folderStructure} // 부모 상태 전달
              folderStatus={folderStatus} // 부모 상태 전달
            />
          </DicomUploadContainer>
          <div>
            <SaveBtn
              onClick={handleSave}
              loading={confirmLoading}
              disabled={!hasPassFolders} // "pass" 상태가 없으면 비활성화
            >
              Save
            </SaveBtn>
          </div>
        </PatientModalContent>
      </PatientModal>
    </>
  );
};

export default RefreshModal;
