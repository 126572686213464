const initialState = {
  rows: 2,
  cols: 2,
};

const GridReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GRID":
      return Object.assign({}, state, {
        ...state,
        rows: action.rows,
        cols: action.cols,
      });
    default:
      return state;
  }
};
export default GridReducer;
