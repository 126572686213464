import React, { useState } from "react";
import styled from "styled-components";
import Slider from "@mui/material/Slider";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 67%;
  padding: 0;
  gap: 5px;
`;
const Bottom = styled.div`
  display: flex;
  width: 90%;
`;
const Right = styled.div`
  display: flex;
  width: 100%;
  color: #fefefe;
  font-family: "Pretendard";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  justify-content: center;
  align-items: center;
  gap: 3px;

  > h1 {
    color: #fefefe;
    font-size: 16px;
    font-weight: 500;
    margin: 0 10px 0 0;
    padding: 0px;
  }

  > input {
    margin: 0px;
    padding: 5px 10px;
    font-family: "Pretendard";
    color: #fefefe;
    font-size: 16px;
    font-weight: 500;
    background-color: #434343;
    border-radius: 10px;
    border: none;
    width: 42px;
    text-align: center;
    outline: none;
    -moz-appearance: textfield;
  }

  > input::-webkit-outer-spin-button,
  > input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const RiskText = styled.div`
  display: flex;
  font-family: "Pretendard";
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;

const SliderChart = (props) => {
  const [value, setValue] = useState(props.threshold);

  // 70%와 30%를 기준으로 스케일링
  const scaleValue = (value) => {
    if (value <= 20) {
      return (value / 20) * 70;
    }
    return 70 + ((value - 20) / 80) * 30;
  };

  const unscaleValue = (value) => {
    if (value <= 70) {
      return (value / 70) * 20;
    }
    return 20 + ((value - 70) / 30) * 80;
  };

  const handleSliderChange = (event, newValue) => {
    const unscaledValue = unscaleValue(newValue);
    const formattedValue = parseFloat(unscaledValue).toFixed(2); // 소수점 2자리까지 제한
    setValue(formattedValue);
    props.handleSliderChange(formattedValue);
  };

  const step = value <= 20 ? 0.1 : 1;

  const marks = [
    { value: scaleValue(0), label: "0%" },
    { value: scaleValue(20), label: "20%" },
    { value: scaleValue(90), label: "90%" },
  ];
  // const marks = [];
  // for (let i = 2; i <= 20; i += 2) {
  //   marks.push({
  //     value: scaleValue(i),
  //   });
  // }

  const handleInputChange = (e) => {
    let inputValue = e.target.value;

    // 숫자만 입력되도록 체크 (숫자 또는 빈 값 허용)
    if (!isNaN(inputValue) || inputValue === "") {
      // 소수점 2자리 이상 입력되지 않도록 처리
      const match = inputValue.match(/^\d*(\.\d{0,2})?$/);
      if (match) {
        setValue(inputValue);
        props.handleSliderChange(inputValue);
      }
    }
  };

  const handleBlur = () => {
    // 빈 값이거나 숫자가 아닌 경우 기본값 3로 설정
    if (value === "" || isNaN(value) || !value || value === null) {
      setValue(parseFloat(3).toFixed(2));
      props.handleSliderChange(parseFloat(3).toFixed(2));
    } else {
      // 입력 완료 후 소수점 2자리 제한
      let formattedValue = parseFloat(value).toFixed(2);
      if (formattedValue > 100) {
        formattedValue = 100;
      }
      setValue(formattedValue);
      props.handleSliderChange(formattedValue);
    }
  };

  return (
    <React.Fragment>
      <Container>
        {/* <RiskText
          risk={props.risk >= props.threshold ? "High" : "low"}
          style={{
            color: props.risk >= props.threshold ? "#F53E3E" : "#4bb8f2",
          }}
        >
          {props.risk >= props.threshold ? "High risk" : "Low risk"}
        </RiskText> */}
        <Right risk={props.risk >= props.threshold ? "High" : "low"}>
          <h1>Cut-off</h1>
          <input
            type="number"
            step="0.1"
            value={value}
            min={0}
            max={100}
            onChange={handleInputChange}
            onBlur={handleBlur} // 포커스 아웃 시 기본값 처리
          />
          <h1>%</h1>
        </Right>
        <Bottom>
          <Slider
            value={scaleValue(value)}
            step={step}
            min={0}
            max={100}
            marks={marks}
            onChange={handleSliderChange}
            sx={{
              //marks label 색상 변경
              "& .MuiSlider-markLabel": {
                color: "#fefefe",
                fontFamily: "Pretendard",
                top: 27,
                fontSize: 11,
              },
              "& .MuiSlider-thumb": {
                color: props.risk >= value ? "#F53E3E" : "#4bb8f2",
              },
              "& .MuiSlider-track": {
                color: props.risk >= value ? "#F53E3E" : "#4bb8f2",
              },
              "& .MuiSlider-rail": {
                color: props.risk >= value ? "#822e2e" : "#2f896f",
              },
              "& .MuiSlider-active": {
                color: props.risk >= value ? "#822e2e" : "#2f896f",
              },
              "& .MuiSlider-mark": {
                color: props.risk >= value ? "#ff8c8c" : "#b8ffeb",
              },
              "& .MuiSlider-mark[data-index='0']": {
                backgroundColor: "#212121",
              },
            }}
          />
        </Bottom>
      </Container>
    </React.Fragment>
  );
};

export default SliderChart;
