import React, { useState, useEffect } from "react";
import { Modal, message, Upload, List } from "antd";
import styled from "styled-components";
import { PaperClipOutlined, CloudUploadOutlined } from "@ant-design/icons";

const FileUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20rem;
`;

const BeforeUpload = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #747474;
  border: dashed 0.15rem #c2c2c2;
  border-radius: 0.5rem;
  cursor: pointer;
`;

const FileUploadModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  top: 55%;

  .ant-modal-content {
    width: 12rem;
    padding: 0.7rem;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    gap: 0.5rem;
  }
`;

const FileUploadBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  border: 0;
  background-color: #fff;
  padding: 0.5rem;
  gap: 0.5rem;
  cursor: pointer;

  > p {
    margin: 0;
    font-family: "Pretendard";
    font-size: 1rem;
    font-weight: 500;
    color: #0d0d0d;
  }
`;

const BeforeUploadIcon = styled(CloudUploadOutlined)`
  font-size: 5rem;
  color: #c2c2c2;
`;

const BeforeUploadTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > h1 {
    font-size: 1.2rem;
    font-weight: 600;
    color: #c2c2c2;
  }
  > p {
    font-size: 1rem;
    font-weight: 400;
    color: #c2c2c2;
  }
`;

const AfterUpload = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #747474;
  border: dashed 0.15rem #c2c2c2;
  border-radius: 0.5rem;
  cursor: pointer;
  overflow-y: auto;
  padding: 0 1rem;

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
`;

const FileName = styled.div`
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.9rem;
  font-weight: 300;
  color: #c5c5c5;
`;

const ListItem = styled(List.Item)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 0.5rem;

  > p {
    width: 10%;
    color: #c5c5c5;
    font-size: 0.9rem;
    font-weight: 300;
  }
`;

const FileUpload = ({ setFileList, onUpload, reset }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [localFileList, setLocalFileList] = useState([]);

  useEffect(() => {
    if (reset) {
      setLocalFileList([]);
    }
  }, [reset]);

  const props = {
    name: "file",
    multiple: true,
    showUploadList: false, // 기본 파일 목록 숨기기
    onRemove: (file) => {
      setLocalFileList((prevList) =>
        prevList.filter((item) => item.uid !== file.uid)
      );
      setFileList((prevList) =>
        prevList.filter((item) => item.uid !== file.uid)
      );
    },
    beforeUpload: (file, localFileList) => {
      const isDcm = file.name.endsWith(".dcm");
      if (!isDcm) {
        message.destroy();
        message.error("You can only upload DICOM files");
        return Upload.LIST_IGNORE;
      }
      // 새 파일 추가
      setLocalFileList(localFileList);
      setFileList(localFileList);
      //fileList.forEach((file) => onUpload(fileList)); // 각 파일 업로드 후 onUpload 호출
      onUpload(file);
      return false; // 자동 업로드 방지
    },

    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        //console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.destroy();
        message.success(
          `${info.file.name} The file has been uploaded successfully`
        );
      } else if (status === "error") {
        message.destroy();
        message.error(`${info.file.name} Failed to upload the file`);
      }
      setIsModalOpen(false);
    },
    localFileList,
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <FileUploadContainer>
      {localFileList.length === 0 ? (
        <BeforeUpload onClick={() => setIsModalOpen(true)}>
          <BeforeUploadIcon />
          <BeforeUploadTextContainer>
            <h1>Upload File</h1>
            <p>Click here to upload the file</p>
          </BeforeUploadTextContainer>
        </BeforeUpload>
      ) : (
        <AfterUpload onClick={() => setIsModalOpen(true)}>
          <div>
            <List
              itemLayout="horizontal"
              dataSource={localFileList}
              renderItem={(item) => (
                <ListItem>
                  <PaperClipOutlined style={{ color: "#fff" }} />
                  <List.Item.Meta title={<FileName>{item.name}</FileName>} />
                  <p>{item.name.split(".").pop()}</p>
                </ListItem>
              )}
            />
          </div>
        </AfterUpload>
      )}
      <FileUploadModal
        footer={null}
        open={isModalOpen}
        setOpen={setIsModalOpen}
        onCancel={handleCancel}
        closeIcon={null}
      >
        <Upload {...props}>
          <FileUploadBtn>
            <PaperClipOutlined />
            <p>Upload files</p>
          </FileUploadBtn>
        </Upload>
        <Upload directory {...props}>
          <FileUploadBtn>
            <PaperClipOutlined />
            <p>Upload Directory</p>
          </FileUploadBtn>
        </Upload>
      </FileUploadModal>
    </FileUploadContainer>
  );
};

export default FileUpload;
