import AxiosConfig from "./axiosConfig";

const axiosInstance = AxiosConfig();

export const InsertPatient = async (params) => {
  try {
    const response = await axiosInstance.post("insertPatient", params);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error(error.response.status);
    }
    throw new Error("환자 등록 실패");
  }
};

export const MultiInsertPatient = async (params) => {
  try {
    const response = await axiosInstance.post("insertPatient/autoSave", params);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error(error.response.status);
    }
    throw new Error("환자 등록 실패");
  }
};

export const MultiSearch = async (params) => {
  try {
    const response = await axiosInstance.post(
      "insertPatient/autoFolderSelect",
      params
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error(error.response.status);
    }
    throw new Error("폴더 찾기 실패");
  }
};
