import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import {
  Table,
  ConfigProvider,
  Input,
  Checkbox,
  Modal,
  Button,
  message,
} from "antd";
import SearchID from "../components/searchID";
import SearchDate from "../components/searchDate";
import SearchStatus from "../components/searchStatus";
import SearchModality from "../components/searchModality";
import SearchSection from "../components/searchSection";
import {
  CaretUpOutlined,
  CaretDownOutlined,
  EyeOutlined,
  SyncOutlined,
  FilterFilled,
  CloseCircleFilled,
  MenuOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import Layout from "../modules/layout/Layout";
import { GetPatientList } from "../api/patientApi";
import InputModal from "../modules/patientList_input/InputModal";
import { RemovePatient } from "../api/removePatient";
import { SearchParamsAction } from "../store/action";
import RefreshModal from "../modules/patientList_input/RefreshModal";
import { UserChkToken } from "../api/userApi";
import store from "../store/store";
import { UserDetailsAction } from "../store/action";
import { Patients_ConnectAI } from "../api/connectAI";
import { PathDetailsAction } from "../store/action";

const PatientTable = styled(Table)`
  .ant-table {
    font-family: "Pretendard";
    font-size: 0.9rem;
  }
  .ant-table-column-sorter {
    color: #fff;
  }
  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    color: #47ddbb;
  }
  .ant-table-column-sorter-up:hover,
  .ant-table-column-sorter-down:hover {
    color: #47ddbb;
  }
  .ant-table-column-sorters:hover .ant-table-column-sorter {
    color: #fff;
  }
  .ant-table-filter-trigger {
    color: #fff;
  }
  .ant-table-filter-trigger:hover {
    color: #fff;
  }
  td.ant-table-column-sort {
    background: unset;
  }
  .ant-checkbox-inner {
    background-color: transparent;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #46d7b6;
  }
  .ant-empty-normal .ant-empty-description {
    color: #fff;
  }
  .ant-table-thead th {
    user-select: none;
  }

  //pagination
  .ant-pagination-item {
    background-color: transparent;
  }
  .ant-pagination-item a {
    color: #fff;
  }
  .ant-pagination-item-active {
    background-color: #4b4b51;
    border-color: #4b4b51;
  }
  .ant-pagination-item-active:hover {
    border-color: #46d7b6;
  }
  .ant-pagination-item-active:hover a {
    color: #46d7b6;
  }
  .ant-pagination-item:not(.ant-pagination-item-active):hover {
    background-color: #4b4b5196;
  }
  .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-disabled:hover .ant-pagination-item-link {
    color: #fff;
  }

  .ant-pagination-prev button,
  .ant-pagination-next button {
    color: #fff;
  }

  .ant-pagination-item-container span {
    color: #fff !important;
  }

  .ant-select-selector {
    color: #fff !important;
    background-color: #4b4b51 !important;
    border: 1px solid #4b4b51 !important;
  }
  .ant-select-arrow {
    color: #fff !important;
  }
  .ant-select-dropdown {
    background-color: #4b4b51;
  }
  .ant-select-dropdown
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: #fff !important;
    background-color: #979797;
  }
  .ant-select-dropdown .ant-select-item {
    color: #fff;
  }
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
      .ant-pagination-size-changer
    )
    .ant-select-selector {
    box-shadow: none;
  }
  .ant-select-dropdown
    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #97979757;
  }
`;

const ChkBox = styled(Checkbox)`
  .ant-checkbox-inner {
    background-color: transparent;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #46d7b6;
  }
`;

const TableSection = styled.section`
  margin: 0;
  padding: 20px;
  width: 100%;
  border-radius: 0 0 10px 10px;
  background-color: #28282c;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;
const TableHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 0 1.5rem 0;
  margin: 0;
  gap: 1.5rem;
`;
const SearchContainer = styled.div`
  display: flex;
  margin: 0;
  padding: 0.5rem 0;
`;

const SearchIDContainer = styled.div`
  display: flex;
  padding-right: 0.5rem;
  border-right: 1px solid #4a5062;
`;
const SearchOtherContainer = styled.div`
  display: flex;
  padding-left: 0.5rem;
  gap: 0.5rem;
`;

const SearchRefreshIcon = styled(SyncOutlined)`
  font-size: 1.3rem;
  color: #47ddbb;
  cursor: pointer;
`;

const TableFilterContainer = styled.div`
  padding: 0.7rem;
  background-color: #4b4b51;
`;

const TableFilterBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const TableFilterItem1 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;

  > div {
    display: flex;
    justify-content: space-between;

    > p {
      margin: 0;
      font-weight: 500;
      font-size: 0.9rem;
      font-family: "Pretendard";
    }

    > button {
      background-color: transparent;
      color: #46d7b6;
      border: none;
    }
  }
`;

const SexFilterChkBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
`;

const TableFilterItem2 = styled.div`
  display: flex;
  gap: 0.3rem;
`;

const TableFilterInput = styled(Input)`
  width: 100%;
  padding: 0.3rem;
  border-radius: 0.2rem;
  border: none;
`;

const TableFilterResetBtn = styled.button`
  font-family: "Pretendard";
  font-weight: 500;
  background-color: #fff;
  border-radius: 0.2rem;
  padding: 0.3rem;
  border: none;
  color: #4b4b51;
  cursor: pointer;
  width: 50%;
`;

const TableFilterSearchBtn = styled.button`
  font-family: "Pretendard";
  font-weight: 500;
  background-color: #46d7b6;
  border-radius: 0.2rem;
  padding: 0.3rem;
  border: none;
  cursor: pointer;
  width: 50%;
`;

const ActionContainer = styled.div`
  display: flex;
  margin: 0;
`;
const AnalysisContianer = styled.div`
  display: flex;
  padding: 0.5rem 10px 0.5rem 0;
  margin: 0;
`;
const AnalysisTopBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;
  width: 8rem;
  margin: 0;
  border-radius: 0.5rem;
  border: 1px solid #46d7b6;
  cursor: pointer;

  > p {
    color: #46d7b6;
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
  }

  @media (max-width: 1800px) {
    width: 7rem;
  }
  @media (max-width: 1700px) {
    width: 6rem;
  }
`;
const UserActionContainer = styled.div`
  padding: 0.5rem 0 0.5rem 10px;
  margin: 0;
  display: flex;
  border-left: 1px solid #4a5062;
  gap: 0.7rem;
`;
const UploadBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;
  width: 8rem;
  margin: 0;
  border-radius: 0.5rem;
  border: 1px solid #46d7b6;
  cursor: pointer;
  > p {
    color: #46d7b6;
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
  }
  @media (max-width: 1800px) {
    width: 7rem;
  }
  @media (max-width: 1700px) {
    width: 6rem;
  }
`;
const RefreshBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.2rem;
  width: 8rem;
  margin: 0;
  border-radius: 0.5rem;
  border: 1px solid #fff;
  cursor: pointer;

  > p {
    color: #46d7b6;
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
  }
  @media (max-width: 1800px) {
    width: 7rem;
  }
  @media (max-width: 1700px) {
    width: 6rem;
  }
`;

const RemoveBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;
  width: 8rem;
  margin: 0;
  border-radius: 0.5rem;
  border: 1px solid #747474;
  cursor: pointer;

  > p {
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
  }

  @media (max-width: 1800px) {
    width: 7rem;
  }
  @media (max-width: 1700px) {
    width: 6rem;
  }
`;

const TableBody = styled.div`
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ViewIcon = styled(EyeOutlined)`
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  color: #fff;
  cursor: pointer;
  border: 1px solid #c3c3c3;
  padding: 0.2rem 0.27rem;
  border-radius: 2px;
`;

const StatusBtnA = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.2rem;
  margin: 0;
  border-radius: 0.5rem;
  background-color: #5f5f5f;
  color: #fff;
  font-family: "Pretendard";
  font-size: 0.9rem;
  font-weight: 300;
  cursor: pointer;
  user-select: none;
`;

const StatusBtnB = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.2rem;
  margin: 0;
  border-radius: 0.5rem;
  background-color: #804145;
  color: #fff;
  font-family: "Pretendard";
  font-size: 0.9rem;
  font-weight: 300;
  cursor: pointer;
  user-select: none;
`;

const StatusBtnC = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.2rem;
  margin: 0;
  border-radius: 0.5rem;
  background-color: #43714a;
  color: #fff;
  font-family: "Pretendard";
  font-size: 0.9rem;
  font-weight: 300;
  user-select: none;
`;

const StatusLoading = styled(LoadingOutlined)`
  font-size: 1.9rem;
  color: #fff;
`;

const RemoveModal = styled(Modal)`
  .ant-modal-content {
    background-color: #303030;
    color: #fefefe;
  }

  .ant-modal-title {
    display: flex;
    justify-content: center;
    background-color: #303030;
    color: #fefefe;
    font-size: 1.5rem;
    font-weight: 600;
  }

  .ant-modal-close-x {
    color: #fefefe;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  span {
    font-family: "Pretendard";
    margin: 0;
  }

  input,
  label,
  button {
    font-family: "Pretendard";
  }
`;

const RemoveModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  padding: 1.5rem 1rem 1rem 1rem;
`;

const RemoveModalTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;

  > p {
    font-size: 16px;
    font-weight: 400;
  }
`;

const RemoveModalBtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

const RemoveModalBtn = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.3rem 3rem;
  border-radius: 0.5rem;
  border: solid 0.1rem #4bf2cc;
  background-color: #303030;
  font-size: 1rem;
  font-weight: 500;
  color: #4bf2cc;
  width: 150px;

  .ant-btn-loading-icon {
    color: #4bf2cc;
  }

  &:hover {
    border-color: #4bf2cc !important;
    color: #4bf2cc !important;
    background: #303030 !important;
  }

  &:disabled {
    border-color: #6e6e6e !important;
    color: #6e6e6e !important;
  }
`;

dayjs.extend(isBetween);

const PatientList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [isRefreshModalOpen, setIsRefreshModalOpen] = useState(false);
  const [isAnalysisModalOpen, setIsAnalysisModalOpen] = useState(false);

  //삭제 모달 로딩
  const [confirmLoading, setConfirmLoading] = useState(false);
  //AI 분석 모달 로딩
  const [isLoading, setIsLoading] = useState(false);

  const userName = useSelector((state) => state.UserDetailsReducer.userName);
  const s_date = useSelector((state) => state.SearchParamsReducer.s_date);
  const s_status = useSelector((state) => state.SearchParamsReducer.s_status);

  const [tableInfo, setTableInfo] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false); // 검색이 수행되었는지 여부를 추적하는 상태
  const [isFiltersCleared, setIsFiltersCleared] = useState(false); // 필터 초기화 상태

  //환자 업로드 여부
  const [isUpload, setIsUpload] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);

  //선택한 체크박스
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [progressNum, setProgressNum] = useState(0); // progress 상태의 개수
  const [intervalId, setIntervalId] = useState(null); // setInterval의 ID 저장
  const intervalRef = useRef(null);

  const [isAnalysis, setIsAnalysis] = useState(false);

  //중복 로그인 방지 처리
  const checkDuplicateLogin = async () => {
    if (!userName) return; // userName이 없으면 함수 종료
    const params = { user_id: userName };
    if (location.pathname !== "/") {
      const localStorageTokenString = localStorage.getItem("luca_userDetails");
      if (!localStorageTokenString) {
        console.error("localStorageToken is null");
        return;
      }
      const localStorageToken = JSON.parse(localStorageTokenString);
      try {
        const serverToken = await UserChkToken(params);
        if (localStorageToken.token !== serverToken[0].token) {
          store.dispatch(UserDetailsAction(null));
          localStorage.removeItem("luca_userDetails");
          navigate(`/`);
        }
      } catch (error) {
        console.error("Error during checkDuplicateLogin:", error);
      }
    }
  };

  checkDuplicateLogin();

  const fetchData = async () => {
    const params = {
      user_id: userName,
    };
    try {
      const data = await GetPatientList(params);
      setTableInfo(data);

      const progressCount = Object.values(data)
        .flat()
        .filter((patient) => patient.resultAI === "progress").length;

      setProgressNum(progressCount);

      if (progressCount === 0 && intervalId) {
        clearInterval(intervalId); // progress가 없으면 setInterval 중단
        setIntervalId(null); // intervalId 초기화
      }

      setIsRefresh(false);
      setIsUpload(false);
    } catch (error) {
      console.log(error);
    }
    setSelectedRows([]);
    setSelectedRowKeys([]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // progressNum이 0이 아니거나 null이 아닐 때만 실행
    if (progressNum !== 0 && progressNum !== null) {
      intervalRef.current = setInterval(() => {
        fetchData();
      }, 8000); // 8초마다 fetchData 호출
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current); // 컴포넌트 언마운트 시 setInterval 중단
      }
    };
  }, [progressNum]);

  useEffect(() => {
    if (isUpload) {
      fetchData();
      setIsUpload(false);
    }
  }, [isUpload]);

  useEffect(() => {
    if (isRefresh) {
      fetchData();
      setIsRefresh(false);
    }
  }, [isRefresh]);

  const handleViewClick = (patient) => {
    dispatch(PathDetailsAction(patient.user_seq, patient.patient_seq));

    navigate(`/Viewer/${patient.user_seq}/${patient.patient_seq}`);
  };

  const handleSearchResult = (searchResults) => {
    console.log("searchResults", searchResults);
    setSearchResults(searchResults);
    setSearchPerformed(true);
    setIsFiltersCleared(false); // 필터 초기화 상태 해제
  };

  const filterBySearchResults = (patients) => {
    if (!searchPerformed) return patients; // 검색이 수행되지 않았을 때 전체 데이터를 표시
    if (searchResults.length === 0) return []; // 검색이 수행된 후 결과가 비어 있으면 빈 배열 반환

    return patients.filter((patient) =>
      searchResults.some(
        (result) =>
          patient.seriesInstanceUID === result.SeriesInstanceUID &&
          patient.studyInstanceUID === result.StudyInstanceUID
      )
    );
  };

  const filterByDate = (patients) => {
    if (!s_date) return patients;

    const [startDate, endDate] = s_date.split(",").map((date) => dayjs(date));
    return patients.filter((patient) =>
      dayjs(patient.reg_date).isBetween(startDate, endDate, "day", "[]")
    );
  };

  const filterByStatus = (patients) => {
    if (!s_status) return patients;

    return patients.filter((patient) => {
      if (s_status === "complete") {
        return patient.resultAI !== null && patient.resultAI !== "error";
      } else if (s_status === "not") {
        return patient.resultAI === null;
      } else if (s_status === "error") {
        return patient.resultAI === "error";
      }
      return true;
    });
  };

  const getFilteredData = () => {
    // tableInfo가 undefined 또는 null인 경우 빈 객체로 초기화
    if (!tableInfo || typeof tableInfo !== "object") {
      return [];
    }
    let filteredData = Object.entries(tableInfo);

    filteredData = filteredData.map(([key, patients]) => {
      let filteredPatients = patients;
      filteredPatients = filterBySearchResults(filteredPatients);
      filteredPatients = filterByDate(filteredPatients);
      filteredPatients = filterByStatus(filteredPatients);

      return [key, filteredPatients];
    });

    // 비어 있지 않은 항목만 반환
    return filteredData.filter(([key, patients]) => patients.length > 0);
  };

  const handleRefresh = () => {
    // 필터 초기화
    setSearchResults([]);
    setSearchPerformed(false);
    dispatch(SearchParamsAction("", "", "", "", ""));
    setIsFiltersCleared(true); // 필터 초기화 상태 설정
    setTimeout(() => setIsFiltersCleared(false), 0); // 다음 렌더링 후 초기화 상태 해제
    fetchData();
  };

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <TableFilterContainer onKeyDown={(e) => e.stopPropagation()}>
        <TableFilterBox>
          <TableFilterItem1>
            <div>
              <p>Search {dataIndex}</p>
              <button
                onClick={() => {
                  close();
                }}
                style={{ cursor: "pointer" }}
              >
                <CloseCircleFilled />
              </button>
            </div>
            <div>
              <TableFilterInput
                ref={searchInput}
                placeholder={`${dataIndex}`}
                value={selectedKeys[0]}
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() =>
                  handleSearch(selectedKeys, confirm, dataIndex)
                }
              />
            </div>
          </TableFilterItem1>
          <TableFilterItem2>
            <TableFilterResetBtn
              onClick={() => {
                clearFilters && handleReset(clearFilters);
                confirm({ closeDropdown: true });
              }}
            >
              Reset
            </TableFilterResetBtn>
            <TableFilterSearchBtn
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            >
              Search
            </TableFilterSearchBtn>
          </TableFilterItem2>
        </TableFilterBox>
      </TableFilterContainer>
    ),
    filterIcon: (filtered) => (
      <MenuOutlined
        style={{
          color: filtered ? "#46d7b6" : "#fff",
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const GenderFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    close,
  }) => {
    const handleSelect = (value) => {
      const currentIndex = selectedKeys.indexOf(value);
      const newSelectedKeys = [...selectedKeys];

      if (currentIndex === -1) {
        newSelectedKeys.push(value);
      } else {
        newSelectedKeys.splice(currentIndex, 1);
      }

      setSelectedKeys(newSelectedKeys);
    };

    return (
      <TableFilterContainer>
        <TableFilterBox>
          <TableFilterItem1>
            <div>
              <p>Search Sex</p>
              <button
                onClick={() => {
                  close();
                }}
                style={{ cursor: "pointer" }}
              >
                <CloseCircleFilled />
              </button>
            </div>
            <SexFilterChkBox>
              <ConfigProvider
                theme={{
                  components: {
                    Checkbox: {
                      colorPrimary: "#46d7b6",
                      colorPrimaryBorder: "#46d7b6",
                      colorPrimaryHover: "#46d7b6",
                      colorText: "#fff",
                    },
                  },
                }}
              >
                <ChkBox
                  checked={selectedKeys.includes("M")}
                  onChange={() => handleSelect("M")}
                >
                  Male
                </ChkBox>
              </ConfigProvider>
              <ConfigProvider
                theme={{
                  components: {
                    Checkbox: {
                      colorPrimary: "#46d7b6",
                      colorPrimaryBorder: "#46d7b6",
                      colorPrimaryHover: "#46d7b6",
                      colorText: "#fff",
                    },
                  },
                }}
              >
                <ChkBox
                  checked={selectedKeys.includes("F")}
                  onChange={() => handleSelect("F")}
                >
                  Female
                </ChkBox>
              </ConfigProvider>
            </SexFilterChkBox>
          </TableFilterItem1>
          <TableFilterItem2>
            <TableFilterResetBtn
              onClick={() => {
                clearFilters();
                confirm();
              }}
            >
              Reset
            </TableFilterResetBtn>
            <TableFilterSearchBtn onClick={() => confirm()}>
              Search
            </TableFilterSearchBtn>
          </TableFilterItem2>
        </TableFilterBox>
      </TableFilterContainer>
    );
  };

  const columns = [
    {
      title: "",
      dataIndex: "expand",
      key: "expand",
      align: "center",
      width: "3%",
      onCell: () => ({
        style: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          userSelect: "none",
        },
      }),
    },
    {
      title: <div style={{ textAlign: "center" }}>Num</div>,
      dataIndex: "Num",
      key: "Num",
      width: "7%",
      onCell: () => ({
        style: {
          paddingLeft: "2.5rem",
          userSelect: "none",
        },
      }),
      sorter: (a, b) => a.Num - b.Num,
    },
    {
      title: "ID",
      dataIndex: "ID",
      key: "ID",
      align: "center",
      width: "12%",
      onCell: () => ({
        style: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          userSelect: "none",
        },
      }),
    },
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
      align: "center",
      width: "10%",
      onCell: () => ({
        style: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          userSelect: "none",
        },
      }),
      ...getColumnSearchProps("Name"),
    },
    {
      title: "Sex",
      dataIndex: "Gender",
      key: "Gender",
      align: "center",
      width: "5%",
      onCell: () => ({
        style: {
          userSelect: "none",
        },
      }),
      filters: [
        { text: "Male", value: "M" },
        { text: "Female", value: "F" },
      ],
      filterDropdown: (props) => <GenderFilterDropdown {...props} />,
      onFilter: (value, record) => record.Gender === value,
      filterIcon: (filtered) => (
        <FilterFilled
          style={{
            color: filtered ? "#46d7b6" : "#fff",
          }}
        />
      ),
    },
    {
      title: "Age",
      dataIndex: "Age",
      key: "Age",
      align: "center",
      width: "5%",
      onCell: () => ({
        style: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          userSelect: "none",
        },
      }),
      sorter: (a, b) => a.Age - b.Age,
    },
    {
      title: "Study Name",
      dataIndex: "StudyName",
      key: "StudyName",
      align: "center",
      width: "16%",
      onCell: () => ({
        style: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          userSelect: "none",
        },
      }),
      ...getColumnSearchProps("StudyName"),
    },
    {
      title: "Scan Date",
      dataIndex: "ScanDate",
      key: "ScanDate",
      align: "center",
      width: "12%",
      onCell: () => ({
        style: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          userSelect: "none",
        },
      }),
      sorter: (a, b) => (dayjs(a.ScanDate).isBefore(b.ScanDate) ? -1 : 1),
      render: (text) => dayjs(text).format("YYYY. MM. DD"),
    },
    {
      title: "Upload Date",
      dataIndex: "UploadDate",
      key: "UploadDate",
      align: "center",
      width: "12%",
      onCell: () => ({
        style: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          userSelect: "none",
        },
      }),
      sorter: (a, b) => (dayjs(a.UploadDate).isBefore(b.UploadDate) ? -1 : 1),
    },
    {
      title: "Result",
      dataIndex: "Result",
      key: "Result",
      align: "center",
      width: "5%",
      onCell: () => ({
        style: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          userSelect: "none",
        },
      }),
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      align: "center",
      width: "8%",
    },
    {
      title: "View",
      dataIndex: "View",
      key: "View",
      align: "center",
      width: "5%",
    },
  ];

  let key = 1;
  const data = getFilteredData().map(([name, patients]) => {
    const currentKey = key++;

    const formatResult = (resultAI) => {
      if (
        resultAI === null ||
        resultAI === "error" ||
        resultAI === "progress"
      ) {
        return "-";
      } else {
        return `${resultAI}%`;
      }
    };

    const handleConnectAI_row = async (resultAI, patient) => {
      const progressCount = Object.values(tableInfo)
        .flat()
        .filter((patient) => patient.resultAI === "progress").length; // progress 상태의 최신 개수 계산

      if (progressCount >= 5) {
        message.destroy();
        message.error(
          "You can select up to 6 data items for analysis at a time."
        );
        return;
      }
      if (
        // CT-CHEST가 아니더라도 분석 가능하게 변경
        //patient.section === "CT-CHEST" &&
        resultAI === null ||
        resultAI === "error"
      ) {
        const params = {
          user_seq: patient.user_seq,
          patient_seq: patient.patient_seq,
          StudyInstanceUID: patient.studyInstanceUID,
          SeriesInstanceUID: patient.seriesInstanceUID,
          patient_age: patient.age,
          patient_gender: patient.gender,
        };

        // Patients_ConnectAI 호출
        Patients_ConnectAI(params)
          .then((response) => {
            //0.5초 후 fetchData() 호출
            setTimeout(() => fetchData(), 150);
          })
          .catch((error) => {
            console.error("Error during analysis.", error);
            message.error("Error occurred during analysis.");
          });
      } else {
        message.destroy();
        message.info(
          "AI analysis will begin. Data that has already been analyzed or is currently being processed will not be analyzed again."
        );
      }
    };

    const formatStatus = (resultAI, patient) => {
      if (resultAI === null) {
        return (
          <StatusBtnA onClick={() => handleConnectAI_row(resultAI, patient)}>
            Analysis
          </StatusBtnA>
        );
      } else if (resultAI === "error") {
        return (
          <StatusBtnB onClick={() => handleConnectAI_row(resultAI, patient)}>
            Status Error
          </StatusBtnB>
        );
      } else if (resultAI === "progress") {
        return <StatusLoading />;
      } else {
        return <StatusBtnC>Complete</StatusBtnC>;
      }
    };

    const item = {
      key: currentKey,
      Num: currentKey,
      ID: patients[0].patientID,
      Name: patients[0].patientName,
      Gender: patients[0].gender,
      Age: patients[0].age,
      StudyName: patients[0].studyDescription,
      ScanDate: patients[0].studyDate,
      UploadDate: new Date(patients[0].reg_date).toLocaleString("ko-KR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      }),
      Result: formatResult(patients[0].resultAI),
      Status: formatStatus(patients[0].resultAI, patients[0]),
      View: <ViewIcon onClick={() => handleViewClick(patients[0])} />,
      patient_seq: patients[0].patient_seq,
      user_seq: patients[0].user_seq,
      seriesInstanceUID: patients[0].seriesInstanceUID,
      studyInstanceUID: patients[0].studyInstanceUID,
      StatusData: patients[0].resultAI,
      section: patients[0].section,
    };

    const children = patients.slice(1).map((patient, index) => {
      const childKey = `${currentKey}-${index + 1}`;
      return {
        key: childKey,
        Num: childKey,
        ID: patient.patientID,
        Name: patient.patientName,
        Gender: patient.gender,
        Age: patient.age,
        StudyName: patient.studyDescription,
        ScanDate: patient.studyDate,
        UploadDate: new Date(patient.reg_date).toLocaleString("ko-KR", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        }),
        Result: formatResult(patient.resultAI),
        Status: formatStatus(patient.resultAI, patient),
        View: <ViewIcon onClick={() => handleViewClick(patient)} />,
        patient_seq: patient.patient_seq,
        user_seq: patient.user_seq,
        seriesInstanceUID: patient.seriesInstanceUID,
        studyInstanceUID: patient.studyInstanceUID,
        StatusData: patient.resultAI,
        section: patient.section,
      };
    });

    if (children.length > 0) {
      item.children = children;
    }

    return item;
  });

  const rowSelection = {
    selectedRowKeys,
    checkStrictly: true,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys); // 선택된 행 키 업데이트
      setSelectedRows(selectedRows); // 선택된 행 업데이트
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows);
    },
  };

  const rowClassName = (record, index) => {
    if (typeof record.key === "number") {
      return "item-row";
    }
    return "child-row";
  };

  const showRemoveModal = () => {
    setIsRemoveModalOpen(true);
  };

  const cancelRemoveModal = () => {
    setIsRemoveModalOpen(false);
  };

  const showAnalysisModal = () => {
    setIsAnalysisModalOpen(true);

    console.log("selectedRows", selectedRows);

    // 선택한 row의 상태값을 가져옴
    const selectRows_status = selectedRows.map((row) => row.StatusData);

    const allAnalyzedOrInProgress = selectRows_status.every(
      (status) =>
        (status !== null && !isNaN(Number(status))) || status === "progress"
    );

    setIsAnalysis(allAnalyzedOrInProgress);

    const nullCount = selectRows_status.filter(
      (status) => status === null
    ).length;
    const errorCount = selectRows_status.filter(
      (status) => status === "error"
    ).length;

    const analysisCount = nullCount + errorCount;

    console.log("analysisCount", analysisCount);

    if (0 > analysisCount > 6) {
      setIsAnalysis(true);
    } else {
      setIsAnalysis(false);
    }
  };

  const cancleAnalysisModal = () => {
    setIsAnalysisModalOpen(false);
  };

  const handleRemove = async () => {
    setConfirmLoading(true);
    for (const row of selectedRows) {
      const params = {
        user_seq: row.user_seq,
        patient_seq: row.patient_seq,
      };
      await RemovePatient(params);
    }
    //삭제 후 환자 리스트 데이터 다시 가져오고 체크박스 모두 해제
    await fetchData();
    setConfirmLoading(false);
    setIsRemoveModalOpen(false);
  };

  //AI 분석 연결
  const handleConnectAI = async () => {
    setIsLoading(true); // 로딩 시작
    message.destroy();
    message.success("Analyze the selected data.");

    const progressCount = Object.values(tableInfo)
      .flat()
      .filter((patient) => patient.resultAI === "progress").length;

    if (progressCount >= 5) {
      message.destroy();
      message.error(
        "You can select up to 6 data items for analysis at a time."
      );

      setIsLoading(false); // 로딩 종료
      setIsAnalysisModalOpen(false); // 모달 닫기
      return;
    }

    if (selectedRows.length > 6) {
      message.destroy();
      message.error(
        "You can select up to 6 data items for analysis at a time."
      );
      setIsLoading(false); // 로딩 종료
      setIsAnalysisModalOpen(false); // 모달 닫기
      return;
    }

    selectedRows.forEach((row) => {
      if (
        row.section === "CT-CHEST" &&
        (row.StatusData === null || row.StatusData === "error")
      ) {
        const params = {
          user_seq: row.user_seq,
          patient_seq: row.patient_seq,
          StudyInstanceUID: row.studyInstanceUID,
          SeriesInstanceUID: row.seriesInstanceUID,
          patient_age: row.Age,
          patient_gender: row.Gender,
        };

        Patients_ConnectAI(params)
          .then((response) => {
            console.log(response);
            fetchData();
          })
          .catch((error) => {
            console.error(error);
            message.destroy();
            message.error("Error occurred during analysis."); // 에러 메시지
            fetchData();
          });
      } else {
        message.destroy();
        message.info(
          "AI analysis will begin. Data that has already been analyzed or is currently being processed will not be analyzed again."
        );
      }
    });

    // 요청을 보낸 후 기다리지 않고 바로 로딩을 종료하고 모달을 닫음
    setIsLoading(false); // 로딩 종료
    setIsAnalysisModalOpen(false); // 모달 닫기
    setTimeout(() => fetchData(), 100);
  };

  const renderExpandIcon = (props) => {
    if (!props.record.children || props.record.children.length === 0) {
      return null;
    }
    return props.expanded ? (
      <CaretUpOutlined
        style={{
          color: "#4bf2cc",
          fontSize: "17px",
          marginRight: "8px",
        }}
        onClick={(e) => props.onExpand(props.record, e)}
      />
    ) : (
      <CaretDownOutlined
        style={{
          color: "#4bf2cc",
          fontSize: "17px",
          marginRight: "8px",
        }}
        onClick={(e) => props.onExpand(props.record, e)}
      />
    );
  };

  return (
    <Layout>
      <TableSection>
        <TableHeader>
          <SearchContainer>
            <SearchIDContainer>
              <SearchID
                idSearchResult={handleSearchResult}
                isFiltersCleared={isFiltersCleared}
              />
            </SearchIDContainer>
            <SearchOtherContainer>
              <SearchDate isFiltersCleared={isFiltersCleared} />
              <SearchStatus isFiltersCleared={isFiltersCleared} />
              <SearchModality
                modalitySearchResult={handleSearchResult}
                isFiltersCleared={isFiltersCleared}
              />
              <SearchSection
                sectionSearchResult={handleSearchResult}
                isFiltersCleared={isFiltersCleared}
              />
              <SearchRefreshIcon onClick={handleRefresh} />
            </SearchOtherContainer>
          </SearchContainer>

          <ActionContainer>
            <AnalysisContianer>
              <AnalysisTopBtn onClick={showAnalysisModal}>
                <p>Analysis</p>
              </AnalysisTopBtn>
              <RemoveModal
                title="Analysis"
                onCancel={cancleAnalysisModal}
                open={isAnalysisModalOpen}
                footer={null}
              >
                <RemoveModalContainer>
                  <RemoveModalTextContainer>
                    {selectedRows.length === 0 ? (
                      <p>There is no data to analysis</p>
                    ) : (
                      <>
                        <p>
                          Do you want to analysis {selectedRows.length} data
                          items?
                        </p>
                        <p>Analysis data cannot be restored</p>
                      </>
                    )}
                  </RemoveModalTextContainer>
                  <RemoveModalBtnContainer>
                    <RemoveModalBtn onClick={cancleAnalysisModal}>
                      Cancel
                    </RemoveModalBtn>
                    <RemoveModalBtn
                      onClick={handleConnectAI}
                      disabled={
                        selectedRows.length === 0 || isAnalysis === true
                      }
                      loading={isLoading}
                    >
                      Analysis
                    </RemoveModalBtn>
                  </RemoveModalBtnContainer>
                </RemoveModalContainer>
              </RemoveModal>
            </AnalysisContianer>
            <UserActionContainer>
              <UploadBtn onClick={() => setIsModalOpen(true)}>
                <p>Upload</p>
              </UploadBtn>
              <InputModal
                open={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                setIsUpload={setIsUpload}
              />
              <RefreshBtn onClick={() => setIsRefreshModalOpen(true)}>
                <p>Multi Upload</p>
              </RefreshBtn>
              <RefreshModal
                open={isRefreshModalOpen}
                setIsRefreshModalOpen={setIsRefreshModalOpen}
                setIsRefresh={setIsRefresh}
              />
              <RemoveBtn onClick={showRemoveModal}>
                <p>Remove</p>
              </RemoveBtn>
              <RemoveModal
                title="Remove"
                onCancel={cancelRemoveModal}
                open={isRemoveModalOpen}
                footer={null}
              >
                <RemoveModalContainer>
                  <RemoveModalTextContainer>
                    {selectedRows.length === 0 ? (
                      <p>There is no data to delete</p>
                    ) : (
                      <>
                        <p>
                          Do you want to delete {selectedRows.length} data
                          items?
                        </p>
                        <p>Deleted data cannot be restored</p>
                      </>
                    )}
                  </RemoveModalTextContainer>
                  <RemoveModalBtnContainer>
                    <RemoveModalBtn onClick={cancelRemoveModal}>
                      Cancel
                    </RemoveModalBtn>
                    <RemoveModalBtn
                      onClick={handleRemove}
                      disabled={selectedRows.length === 0}
                      loading={confirmLoading}
                    >
                      Remove
                    </RemoveModalBtn>
                  </RemoveModalBtnContainer>
                </RemoveModalContainer>
              </RemoveModal>
            </UserActionContainer>
          </ActionContainer>
        </TableHeader>
        <TableBody>
          <ConfigProvider
            theme={{
              components: {
                Table: {
                  colorBgContainer: "#28282c",
                  colorBorderSecondary: "#191919",
                  rowHoverBg: "#375754",
                  controlItemBgActive: "#375754",
                  controlItemBgActiveHover: "#375754",
                  headerColor: "#ffffff",
                  colorText: "#ffffff",
                  headerBg: "#39393e",
                  headerFilterHoverBg: "#39393e",
                  headerSortActiveBg: "#39393e",
                  headerSortHoverBg: "#39393e",
                },
                Checkbox: {
                  colorPrimary: "#46d7b6",
                  colorPrimaryBorder: "#46d7b6",
                  colorPrimaryHover: "#46d7b6",
                  colorText: "#fff",
                },
              },
            }}
          >
            <PatientTable
              //pagination={false}
              columns={columns}
              dataSource={data}
              pagination={{ position: ["bottomRight"] }}
              rowSelection={{
                ...rowSelection,
              }}
              rowClassName={rowClassName}
              expandable={{
                expandIcon: renderExpandIcon,
                expandIconColumnIndex: 2,
              }}
            />
          </ConfigProvider>
        </TableBody>
      </TableSection>
    </Layout>
  );
};

export default PatientList;
