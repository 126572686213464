import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import styled from "styled-components";
import {
  RenderingEngine,
  Enums,
  getRenderingEngine,
  eventTarget,
} from "@cornerstonejs/core";
import {
  StackScrollMouseWheelTool,
  ToolGroupManager,
  addTool,
  Enums as csToolsEnums,
  WindowLevelTool,
  ZoomTool,
  PanTool,
  StackScrollTool,
  synchronizers,
  SynchronizerManager,
  PlanarRotateTool,
  ProbeTool,
  LengthTool,
  BidirectionalTool,
  AngleTool,
  CobbAngleTool,
  RectangleROITool,
  CircleROITool,
  EllipticalROITool,
} from "@cornerstonejs/tools";
import * as cornerstoneTools from "@cornerstonejs/tools";
import initDemo from "./utils/helper/initDemo";
import createImageIdsAndCacheMetaData_STACK from "./utils/helper/createImageIdsAndCacheMetaData_STACK";
import DicomPorts from "./DicomPorts.jsx";
import { loadStackImages } from "./loadVolume.js";
import { useSelector } from "react-redux";
import cornerstoneDICOMImageLoader from "@cornerstonejs/dicom-image-loader";
import dcmjs from "dcmjs";
import removeInvalidTags from "./utils/helper/removeInvalidTags.js";
import BasicModal from "../basicModal/basicModal.jsx";

const { REACT_APP_ORTHANC_URL } = process.env;

const Container = styled.div`
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const DicomEngine = forwardRef((props, ref) => {
  const [dicomError, setDicomError] = useState(null);
  const [errModalOpen, setErrModalOpen] = useState(false);

  //WWWC lung setting을 위한 변수
  const [pixelValue, setPixelValue] = useState({
    intercept: null,
    slope: null,
  });
  const lungLowerHU = -1450;
  const lungUpperHU = -50;

  const generateGridData = (rows, cols) => {
    const gridData = [];
    const viewportIds = [];
    for (let row = 0; row < rows; row++) {
      const currentRow = [];
      for (let col = 0; col < cols; col++) {
        currentRow.push({ id: String(row + 1) + String(col + 1) });
        viewportIds.push(String(row + 1) + String(col + 1));
      }
      gridData.push(currentRow);
    }
    return { gridData, viewportIds };
  };

  const dTool = useSelector((state) => state.DtoolReducer);
  const gridSize = useSelector((state) => state.GridReducer);
  const { gridData, viewportIds } = generateGridData(
    gridSize.rows,
    gridSize.cols
  );
  const [activePorts, setActivePorts] = useState(["11"]);
  const [selectedAnnotation, setSelectedAnnotation] = useState("");

  const toolGroupId = props.toolGroupId;
  const renderingEngineId = props.renderingEngineId;
  const imageSliceSynchronizerId = props.imageSliceSynchronizerId;

  const { MouseBindings, Events: toolsEvents } = csToolsEnums;
  const { ViewportType } = Enums;
  const { createImageSliceSynchronizer } = synchronizers;
  const { DicomMetaDictionary } = dcmjs.data;

  const setupTools = (toolGroupId) => {
    const toolGroup = ToolGroupManager.createToolGroup(toolGroupId);

    addTool(StackScrollMouseWheelTool);
    addTool(WindowLevelTool);
    addTool(ZoomTool);
    addTool(PanTool);
    addTool(StackScrollTool);
    addTool(PlanarRotateTool);
    addTool(ProbeTool);
    addTool(LengthTool);
    addTool(BidirectionalTool);
    addTool(AngleTool);
    addTool(CobbAngleTool);
    addTool(RectangleROITool);
    addTool(CircleROITool);
    addTool(EllipticalROITool);

    toolGroup.addTool(StackScrollMouseWheelTool.toolName);
    toolGroup.addTool(WindowLevelTool.toolName);

    toolGroup.setToolActive(StackScrollMouseWheelTool.toolName);
    toolGroup.setToolActive(WindowLevelTool.toolName, {
      bindings: [
        {
          mouseButton: MouseBindings.Primary,
        },
      ],
    });

    createImageSliceSynchronizer(imageSliceSynchronizerId);
    return toolGroup;
  };

  function updateAnnotationDiv(uid) {
    const annotation = cornerstoneTools.annotation.state.getAnnotation(uid);
    if (!annotation) {
      setSelectedAnnotation("");
    } else {
      setSelectedAnnotation(uid);
    }
  }

  function annotationModifiedListener(evt) {
    updateAnnotationDiv(
      evt.detail.annotation?.annotationUID ||
        evt.detail.annotationUID ||
        evt.detail.added?.[0]
    );
  }
  function addAnnotationListeners() {
    eventTarget.addEventListener(
      toolsEvents.ANNOTATION_SELECTION_CHANGE,
      annotationModifiedListener
    );
    eventTarget.addEventListener(
      toolsEvents.ANNOTATION_MODIFIED,
      annotationModifiedListener
    );
    eventTarget.addEventListener(
      toolsEvents.ANNOTATION_COMPLETED,
      annotationModifiedListener
    );
    eventTarget.addEventListener(
      toolsEvents.ANNOTATION_REMOVED,
      annotationModifiedListener
    );
  }

  useEffect(() => {
    const run = async () => {
      if (!props.studyInstanceUID || !props.seriesInstanceUID) return;

      if (dicomError === 1) return;

      const renderingEngine = getRenderingEngine(renderingEngineId);

      let imageIds;
      try {
        imageIds = await createImageIdsAndCacheMetaData_STACK({
          StudyInstanceUID: props.studyInstanceUID,
          SeriesInstanceUID: props.seriesInstanceUID,
          wadoRsRoot: `http://${REACT_APP_ORTHANC_URL}/orthanc`,
        });
      } catch (error) {
        console.log("Error in createImageIdsAndCacheMetaData:", error.message);
        setDicomError(1);
        setErrModalOpen(true);
        return; // 에러 발생 시 함수 실행 중단
      }

      if (renderingEngine === undefined) {
        await initDemo();
        const renderingEngine = new RenderingEngine(renderingEngineId); // Instantiate a rendering engine
        props.onReady(true); // 렌더링 엔진 준비 완료 콜백 호출
        const toolGroup = setupTools(toolGroupId); //create tool group

        await Promise.all(
          viewportIds.map(async (viewportId) => {
            // await renderingEngine.enableElement({
            //   viewportId,
            //   type: ViewportType.ORTHOGRAPHIC,
            //   element: document.getElementById(viewportId),
            // });
            await renderingEngine.enableElement({
              viewportId: viewportId,
              type: ViewportType.STACK,
              element: document.getElementById(viewportId),
            });
            toolGroup.addViewport(viewportId, renderingEngineId);
          })
        );

        // await loadVolume(
        //   renderingEngine,
        //   viewportIds[0],
        //   imageIds,
        //   "Original",
        //   props.user_seq,
        //   props.patient_seq
        // );

        // 스택 이미지 로드 및 렌더링
        const loadStackImages_result = await loadStackImages(
          renderingEngine,
          viewportIds[0],
          imageIds,
          "original"
        );

        // pixelValue 값을 상태로 저장
        setPixelValue({
          intercept: loadStackImages_result.intercept,
          slope: loadStackImages_result.slope,
        });

        let instanceMetaData =
          cornerstoneDICOMImageLoader.wadors.metaDataManager.get(imageIds[0]);
        instanceMetaData = removeInvalidTags(instanceMetaData);
        const metadata =
          DicomMetaDictionary.naturalizeDataset(instanceMetaData);

        const convertObjectToArray = (inputObj) => {
          const dataArray = Object.keys(inputObj).map((key, index) => {
            let value = inputObj[key];

            // Convert arrays and objects to strings
            if (Array.isArray(value) || typeof value === "object") {
              value = JSON.stringify(value);
            }

            return {
              tag: key,
              value: value,
              key: index + 1,
            };
          });

          return dataArray;
        };

        const dataArray = convertObjectToArray(metadata);
        props.onTagsUpdated(dataArray);
      } else {
        const previousViewports = renderingEngine
          .getStackViewports()
          .map((viewPort) => viewPort.id);
        // const previousViewports = renderingEngine
        //   .getVolumeViewports()
        //   .map((viewPort) => viewPort.id);
        const toolGroup = ToolGroupManager.getToolGroup(toolGroupId);

        viewportIds.forEach((viewportId) => {
          const vp = renderingEngine.getViewport(viewportId);
          if (vp === undefined) {
            renderingEngine.enableElement({
              viewportId: viewportId,
              // type: ViewportType.ORTHOGRAPHIC,
              type: ViewportType.STACK,
              element: document.getElementById(viewportId),
            });
            toolGroup.addViewport(viewportId, renderingEngineId);
            loadStackImages(renderingEngine, viewportId, imageIds, "original");
            // loadVolume(
            //   renderingEngine,
            //   viewportId,
            //   imageIds,
            //   "Original-Grid",
            //   props.user_seq,
            //   props.patient_seq
            // );
          } else {
            renderingEngine.resize();
          }
        });
        if (props.isSync) {
          const synchronizer = SynchronizerManager.getSynchronizer(
            imageSliceSynchronizerId
          );
          if (!synchronizer) {
            return;
          }
          setActivePorts(viewportIds);
          viewportIds.forEach((viewportId) =>
            synchronizer.add({
              renderingEngineId: renderingEngineId,
              viewportId: viewportId,
            })
          );
        }

        const removablePorts = previousViewports.filter(
          (item) => !viewportIds.includes(item)
        );
        removablePorts.forEach((viewportId) => {
          renderingEngine.disableElement(viewportId);
        });

        props.onReady(true); // 렌더링 엔진 준비 완료 콜백 호출
      }
    };
    if (viewportIds.length > 0) {
      run();
      addAnnotationListeners();
    }
  }, [
    props.studyInstanceUID,
    props.seriesInstanceUID,
    props.user_seq,
    props.patient_seq,
    gridSize,
  ]);

  useEffect(() => {
    if (dicomError === 1) return;

    const toolGroup = ToolGroupManager.getToolGroup(toolGroupId);
    if (toolGroup !== undefined) {
      toolGroup.addTool(dTool.currentDtool);
      toolGroup.setToolActive(dTool.currentDtool, {
        bindings: [
          {
            mouseButton: MouseBindings.Primary,
          },
        ],
      });
      toolGroup.setToolPassive(dTool.previouisDtool);
    }
  }, [dTool]);

  useEffect(() => {
    const synchronizer = SynchronizerManager.getSynchronizer(
      imageSliceSynchronizerId
    );
    if (!synchronizer) {
      return;
    }
    if (props.isSync) {
      setActivePorts(viewportIds);
      viewportIds.forEach((viewportId) =>
        synchronizer.add({
          renderingEngineId: renderingEngineId,
          viewportId: viewportId,
        })
      );
    } else {
      setActivePorts(["11"]);
      viewportIds.forEach((viewportId) =>
        synchronizer.remove({
          renderingEngineId: renderingEngineId,
          viewportId: viewportId,
        })
      );
    }
  }, [props.isSync]);

  const handlePortActive = (id) => {
    if (dicomError === 1) return;

    if (!props.isSync) {
      setActivePorts([id]);
    }
  };

  useImperativeHandle(ref, () => ({
    handleReset() {
      if (dicomError === 1) return;

      const renderingEngine = getRenderingEngine(renderingEngineId);
      activePorts.forEach((viewportId) => {
        const viewport = renderingEngine.getViewport(viewportId);

        // const viewportKey = viewport._actors.keys().next().value;
        // const segment = "cornerstoneStreamingImageVolume:LUCAVOLUME:Segment";
        // const originalGrad =
        //   "cornerstoneStreamingImageVolume:LUCAVOLUME:Original Gradcam";
        // const segmentGrad =
        //   "cornerstoneStreamingImageVolume:LUCAVOLUME:Segment gradcam";
        const segment = "segment";
        const originalGrad = "original_gradcam";
        const segmentGrad = "segment_gradcam";

        const lowerPixelValue =
          (lungLowerHU - pixelValue.intercept) / pixelValue.slope;
        const upperPixelValue =
          (lungUpperHU - pixelValue.intercept) / pixelValue.slope;

        const ImageIds = viewport.imageIds;
        if (ImageIds.length < 10) {
          viewport.setStack(viewport.imageIds, 0);
        } else if (ImageIds.length >= 10 && ImageIds.length < 60) {
          viewport.setStack(viewport.imageIds, 11);
        } else if (ImageIds.length >= 60) {
          viewport.setStack(viewport.imageIds, 35);
        }

        if (
          viewport.viewportKey === segment ||
          viewport.viewportKey === originalGrad ||
          viewport.viewportKey === segmentGrad
        ) {
          viewport.setProperties({ voiRange: { lower: 0, upper: 255 } });
        } else {
          viewport.setProperties({
            voiRange: { lower: lowerPixelValue, upper: upperPixelValue },
          });
        }
        viewport.setProperties({
          rotation: 0,
        });
        viewport.render();
      });
    },

    handlePortRotate() {
      if (dicomError === 1) return;

      const renderingEngine = getRenderingEngine(renderingEngineId);
      activePorts.forEach((viewportId) => {
        const viewport = renderingEngine.getViewport(viewportId);
        const { rotation } = viewport.getProperties();
        if (rotation !== undefined) {
          viewport.setProperties({ rotation: rotation + 90 });
        } else {
          viewport.setProperties({ rotation: 90 });
        }
        viewport.render();
      });
    },
    handlePortRotateInverse() {
      if (dicomError === 1) return;

      const renderingEngine = getRenderingEngine(renderingEngineId);
      activePorts.forEach((viewportId) => {
        const viewport = renderingEngine.getViewport(viewportId);
        const { rotation } = viewport.getProperties();
        if (rotation !== undefined) {
          viewport.setProperties({ rotation: rotation - 90 });
        } else {
          viewport.setProperties({ rotation: -90 });
        }
        viewport.render();
      });
    },

    handleDelete() {
      if (dicomError === 1) return;

      if (selectedAnnotation !== "") {
        const renderingEngine = getRenderingEngine(renderingEngineId);
        cornerstoneTools.annotation.state.removeAnnotation(selectedAnnotation);
        viewportIds.forEach((viewportId) => {
          const viewport = renderingEngine.getViewport(viewportId);
          viewport.render();
        });
      }
    },
    handleColor(Color) {
      if (dicomError === 1) return;

      const renderingEngine = getRenderingEngine(renderingEngineId);
      activePorts.forEach((viewportId) => {
        const viewport = renderingEngine.getViewport(viewportId);
        viewport.setProperties({ colormap: { name: Color } });
        viewport.render();
      });
    },
    handleWWWC(settingName) {
      if (dicomError === 1) return;

      const renderingEngine = getRenderingEngine(renderingEngineId);
      let windowWidth, windowCenter;

      // 설정 이름에 따라 WWWC 값을 설정합니다.
      switch (settingName) {
        case "Brain":
          windowWidth = 80;
          windowCenter = 40;
          break;
        case "Lung":
          windowWidth = 1500;
          windowCenter = -700;
          break;
        case "Abdomen":
          windowWidth = 400;
          windowCenter = 20;
          break;
        case "Bone":
          windowWidth = 2056;
          windowCenter = 250;
          break;
        case "Liver":
          windowWidth = 150;
          windowCenter = 90;
          break;
        default:
          windowWidth = 400;
          windowCenter = 40;
      }

      let lower = windowCenter - windowWidth / 2.0;
      let upper = windowCenter + windowWidth / 2.0;

      let lowerPixelValue_setting =
        (lower - pixelValue.intercept) / pixelValue.slope;
      let upperPixelValue_setting =
        (upper - pixelValue.intercept) / pixelValue.slope;

      activePorts.forEach((viewportId) => {
        if (dicomError === 1) return;

        const viewport = renderingEngine.getViewport(viewportId);
        viewport.setProperties({
          voiRange: {
            lower: lowerPixelValue_setting,
            upper: upperPixelValue_setting,
          },
        });
        //viewport.resetCamera();
        viewport.render();
      });
    },
  }));

  return (
    <Container>
      {gridData.map((row, rowIndex) => (
        <Row key={rowIndex}>
          {row.map((cell, colIndex) => (
            <DicomPorts
              key={colIndex}
              id={cell.id}
              renderEngineId={renderingEngineId}
              isactiveport={activePorts.includes(cell.id)}
              handlePortActive={handlePortActive}
            />
          ))}
        </Row>
      ))}
      <BasicModal
        open={errModalOpen}
        setIsBasicModalOpen={setErrModalOpen}
        title="Error - Missing Metadata"
        content={["The DICOM file cannot be loaded due to missing metadata"]}
        closeBtn="Close"
      />
    </Container>
  );
});

export default DicomEngine;
